import {
  ProgrammeBookingStatus,
  ProgrammeSessionBookingStatus,
  type DateTimeString,
} from '@activesg/db'

import { dayjs } from './dayjs'

export const getStartAndEndDateTimeForSession = (
  day: Date,
  startTimeInMs: number,
  endTimeInMs: number,
) => {
  const startOfDay = dayjs(day).tz().startOf('day')

  return {
    startDateTime: startOfDay.add(startTimeInMs, 'ms').toDate(),
    endDateTime: startOfDay.add(endTimeInMs, 'ms').toDate(),
  }
}

export const PROGRAMME_BOOKING_STATUS_TO_LABEL: Record<
  ProgrammeBookingStatus,
  string
> = {
  CANCELLED: 'Withdrawn',
  CONFIRMED: 'Confirmed',
  PENDING: 'Pending',
} as const

export const hasAnySessionElapsed = (
  sessions: {
    startDateTime: DateTimeString
  }[],
) => {
  return sessions.some((session) => {
    return dayjs(session.startDateTime).tz().isBefore(dayjs().tz())
  })
}

export function mapProgrammeBookingStatusToSessionStatus(
  status: ProgrammeBookingStatus,
  isRefund?: boolean,
) {
  switch (status) {
    case ProgrammeBookingStatus.CANCELLED: {
      return isRefund
        ? ProgrammeSessionBookingStatus.CANCELLED_WITH_REFUND
        : ProgrammeSessionBookingStatus.CANCELLED_WITHOUT_REFUND
    }
    case ProgrammeBookingStatus.PENDING: {
      return ProgrammeSessionBookingStatus.PENDING
    }
    case ProgrammeBookingStatus.CONFIRMED: {
      return ProgrammeSessionBookingStatus.CONFIRMED
    }
  }
}
