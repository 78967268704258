import hash from 'imurmurhash'

import {
  type DB,
  type SafeKysely,
  type Subvenue,
  type Venue,
} from '@activesg/db'
import { isPresent, ld, trpcAssert } from '~common/utils'

import { type Logger } from '../logger'

export const POSTGRES_MAX_SAFE_INTEGER = 2147483647

export function getAdvisoryLockKey(key: string) {
  return new hash(key).result() % POSTGRES_MAX_SAFE_INTEGER
}

export type CreateTimeslotLockArgs =
  | {
      venueId: Venue['id']
      timeslot: number
    }
  | {
      subvenueId: Subvenue['id']
      timeslot: number
    }

export const createTimeslotLocks = async (
  db: SafeKysely<DB>,
  logger: Logger,
  ...bookings: CreateTimeslotLockArgs[]
) => {
  const lgr = logger.createScopedLogger({ action: 'createTimeslotLocks' })

  const locks: [number, number][] = []

  const subvenueIds = bookings
    .map((b) => ('subvenueId' in b ? b.subvenueId : undefined))
    .filter(isPresent)

  const subvenueToVenue: { id: Venue['id']; subvenueId: Subvenue['id'] }[] = []

  if (subvenueIds.length) {
    subvenueToVenue.push(
      ...(await db
        .selectFrom('Subvenue')
        .innerJoin('Venue', 'Venue.id', 'Subvenue.venueId')
        .select(['Subvenue.id as subvenueId', 'Venue.id'])
        .where('Subvenue.id', 'in', subvenueIds)
        .execute()),
    )
  }

  for (const booking of bookings) {
    const date = ld.startOfDay(booking.timeslot)
    const venueId =
      'venueId' in booking
        ? booking.venueId
        : subvenueToVenue.find((v) => v.subvenueId === booking.subvenueId)?.id
    trpcAssert(venueId, {
      message: 'Venue not found',
      code: 'NOT_FOUND',
      context: { ...booking },
      logger: lgr,
    })
    locks.push([
      (date.valueOf() % 1000) % POSTGRES_MAX_SAFE_INTEGER,
      new hash(venueId).result() % POSTGRES_MAX_SAFE_INTEGER,
    ])
  }

  // Locks MUST be sorted to prevent deadlocks.
  locks.sort((a, b) => (a[0] === b[0] ? a[1] - b[1] : a[0] - b[0]))

  return locks
}
