import { LocalDate } from 'tz-local-date'
import { z } from 'zod'

import { TIMEZONE } from '~common/constants/date'

import { dayjs } from './dayjs'

export enum Day {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

const dayOfWeekSchema = z.nativeEnum(Day)

export const validateDayOfWeek = (dayOfWeek: string) => {
  const result = dayOfWeekSchema.safeParse(dayOfWeek.toUpperCase())
  if (!result.success) {
    return null
  }
  return result.data
}

export const isBefore = (reference: Date) => (date: Date) =>
  dayjs(date).tz().isBefore(dayjs(reference).tz(), 'day')

export const isBeforeToday = (date: Date) =>
  dayjs(date).tz().isBefore(dayjs(new Date()).tz(), 'day')

export const isAfter = (reference: Date) => (date: Date) =>
  dayjs(date).tz().isAfter(dayjs(reference).tz(), 'day')

export const isAfterToday = (date: Date) =>
  dayjs(date).tz().isAfter(dayjs(new Date()).tz(), 'day')

export const ld = new LocalDate(TIMEZONE, new Date())

export const toDay = (day: NonNullable<ReturnType<typeof ld.getDay>>): Day => {
  switch (day) {
    case 'Monday':
      return Day.MON
    case 'Tuesday':
      return Day.TUE
    case 'Wednesday':
      return Day.WED
    case 'Thursday':
      return Day.THU
    case 'Friday':
      return Day.FRI
    case 'Saturday':
      return Day.SAT
    case 'Sunday':
      return Day.SUN
    default:
      day satisfies never
      throw new Error('Invalid path')
  }
}

export function calculateAge(dob: Date): { year: number; month: number }
export function calculateAge(dob: Date | null): {
  year?: number
  month?: number
}
export function calculateAge(dob: Date | null): {
  year?: number
  month?: number
} {
  if (!dob) return {}
  const today = new Date()
  const dobDate = dayjs(dob).tz()

  const year = Math.abs(dobDate.diff(today, 'year'))
  const month = Math.abs(dobDate.diff(today, 'month')) - year * 12
  return { year, month }
}

export function calculateYearAge(dob: Date, reference?: Date): number {
  const dateReferenceYear = ld.getComponents(reference ?? new Date()).year

  const dobYear = ld.getComponents(dob).year

  return Math.abs(dateReferenceYear - dobYear)
}

export function padMonthWithZero(month: number) {
  return month.toString().padStart(2, '0')
}

export function min(first: Date, ...rest: Date[]) {
  return rest.reduce((acc, date) => (date < acc ? date : acc), first)
}

export function max(first: Date, ...rest: Date[]) {
  return rest.reduce((acc, date) => (date > acc ? date : acc), first)
}

export function addDays(date: Date, days: number): Date {
  return new Date(date.valueOf() + days * 24 * 60 * 60 * 1000)
}

export function addMonthsNoRollover(date: Date, months: number) {
  const currentDate = date.getUTCDate()
  const currentMonth = date.getUTCMonth()

  const transformedDate = new Date(date)
  transformedDate.setUTCMonth(currentMonth + months, currentDate)
  if (transformedDate.getUTCDate() !== currentDate) {
    transformedDate.setUTCDate(1)
    return new Date(transformedDate.valueOf() - 86400 * 1000)
  }
  return transformedDate
}

export const msToTime = (durationInMs: number) => {
  const milliseconds = Math.floor((durationInMs % 1000) / 100)
  const seconds = Math.floor((durationInMs / 1000) % 60)
  const minutes = Math.floor((durationInMs / (1000 * 60)) % 60)
  const hours = Math.floor((durationInMs / (1000 * 60 * 60)) % 24)

  return {
    hours,
    minutes,
    seconds,
    milliseconds,
  }
}
