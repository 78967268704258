/**
 * Do not edit ./generatedTypes and ./generatedEnums files directly.
 * They are auto generated by kysely-prisma.
 */

import {
  Kysely as NativeKysely,
  type Transaction as NativeTransaction,
} from 'kysely'

export * from './augmented-types'
export * as Model from './generated-types'
export type { DB } from './generated-types'
export * from './generated-enums'
export * from './selectable-types'
export type { Insertable, Updateable, QueryNode } from 'kysely'
export { sql } from 'kysely'
export * from './helpers'

export class Kysely<DB> extends NativeKysely<DB> {
  static readonly #identifier: unique symbol = Symbol()

  #identity(): symbol {
    return (Kysely<DB>).#identifier
  }
}

export type Transaction<DB> = Omit<NativeTransaction<DB>, 'transaction'>

export type SafeKysely<DB> = Transaction<DB> | Kysely<DB>
