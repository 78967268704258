import { type PropsWithChildren } from 'react'

import { FullscreenSpinner } from '~common/components'
import { useLoginState, useRedirectWithState } from '~common/hooks'

interface EnforceLoginStatePageWrapperProps {
  /**
   * Route to redirect to when user is not authenticated.
   */
  redirectTo: string
}

const Redirect = ({ redirectTo }: EnforceLoginStatePageWrapperProps) => {
  const { redirect } = useRedirectWithState(redirectTo)

  void redirect()

  return <FullscreenSpinner />
}

/**
 * Page wrapper that renders children only if the login state localStorage flag has been set.
 * Otherwise, will redirect to the route passed into the `redirectTo` prop.
 *
 * @note 🚨 There is no authentication being performed by this component. This component is merely a wrapper that checks for the presence of the login flag in localStorage. This means that a user could add the flag and bypass the check. Any page children that require authentication should also perform authentication checks in that page itself!
 */
export const EnforceLoginStatePageWrapper = ({
  redirectTo,
  children,
}: PropsWithChildren<EnforceLoginStatePageWrapperProps>): React.ReactElement => {
  const { hasLoginStateFlag } = useLoginState()

  if (hasLoginStateFlag) {
    return <>{children}</>
  }

  return <Redirect redirectTo={redirectTo} />
}
