import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { anatomy } from '@chakra-ui/theme-tools'

export const TIME_CALENDAR_THEME_KEY = 'TimeCalendar'

const parts = anatomy(TIME_CALENDAR_THEME_KEY).parts('calendar')

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  calendar: {
    '.fc-v-event': {
      border: 'none',
    },
    /* Override default event styles */
    '.fc-timegrid-event': {
      borderWidth: 0,
      opacity: 1,
      boxShadow: 'none',
    },
    '.fc-timegrid-event .fc-event-main': {
      padding: 0,
      backgroundColor: 'transparent',
    },
    '.fc-timegrid-event-harness.fc-timegrid-event-harness-inset': {
      overflow: 'hidden',
      marginBottom: '1px',
    },
    '.fc-direction-ltr .fc-timegrid-col-events': {
      margin: 0,
    },
    /* Removes horizontal lines between each hour */
    '.fc-timegrid-slot-minor': {
      border: 'none',
    },
    /* Removes border at the top between the day headers*/
    th: {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
    /* Removes border around calendar */
    '.fc-scrollgrid': {
      border: 'none',
    },
    /* Hide scrollbar from time label */
    '.fc-scroller-harness .fc-scroller': {
      overflow: 'auto hidden !important',
    },
    /* Hide scrollbar from header */
    '.fc-scrollgrid-section-header .fc-scroller': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
    '.fc-scrollgrid-section-header .fc-scroller::-webkit-scrollbar': {
      display: 'none',
    },
    /* Show consistent border */
    '.fc-col-header-cell': {
      borderBottom: 0,
    },
    '.fc-timegrid-axis': {
      borderBottom: 0,
    },
    '.fc-scrollgrid-section > td': {
      borderTopWidth: '0px',
      borderBottomWidth: '0px',
      borderRightWidth: '0px',
    },
    /* Add border to the top and bottom of the calendar */
    '.fc-scrollgrid-section table': {
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
    },
    /* Add side borders for each day */
    '.fc-timegrid-col': {
      borderLeft: '2px solid #EBEBEB',
    },
    /* Remove yellow background for current day */
    '.fc-timegrid-col.fc-day-today': {
      backgroundColor: 'transparent',
    },
    /* Add top margin to prevent top label from being cut off, add borders */
    '.fc-timegrid-slots': {
      marginY: '5px',
      borderRight: '2px solid #EBEBEB',
      borderBottom: '2px solid #EBEBEB',
    },
    '.fc-timegrid-axis-chunk': {
      marginY: '5px',
      borderRight: '2px solid #EBEBEB',
      paddingBottom: '1px', // To align with the main panel border
      borderBottom: '2px solid rgba(235, 235, 235, .4)',
    },
    /* Time label override */
    '.fc-timgrid-axis-chunk .fc-direction-ltr .fc-timegrid-slot-label-frame': {
      textAlign: 'left',
    },
    '.fc-timegrid-axis-chunk tr:nth-of-type(2n+1) .fc-timegrid-slot-label:not(.fc-timegrid-slot-minor)':
      {
        borderTop: '2px solid rgba(235, 235, 235, .4)',
        borderRight: 'none',
      },
    /* Time slot override */
    '.fc-timegrid-slots tr:nth-of-type(2n+1) .fc-timegrid-slot-label:not(.fc-timegrid-slot-minor)':
      {
        borderTop: '2px solid rgba(235, 235, 235, .4)',
        borderRight: 'none',
        transform: 'translateY(-50%)',
      },
    '.fc-timegrid-slots tr:nth-of-type(4n+1) .fc-timegrid-slot-label:not(.fc-timegrid-slot-minor)':
      {
        borderTop: '2px solid #EBEBEB',
        transform: 'translateY(-50%)',
      },
    '.fc-timegrid-slots tr:nth-of-type(2n+1) .fc-timegrid-slot-label p': {
      display: 'none',
    },
    '.fc-timegrid-slots tr:nth-of-type(4n+1) .fc-timegrid-slot-label p': {
      display: 'block',
    },
    '.fc-timegrid-slots .fc-timegrid-slot.fc-timegrid-slot-lane': {
      lineHeight: '1.5rem',
      height: 'calc(1.5rem + 1px)',
    },
    '.fc-timegrid-slots tr:nth-of-type(2n+1) .fc-timegrid-slot.fc-timegrid-slot-lane':
      {
        border: 'none',
      },
    '.fc-timegrid-slots tr:nth-of-type(4n) .fc-timegrid-slot.fc-timegrid-slot-lane':
      {
        borderBottom: '2px solid #EBEBEB',
      },
    '.fc-timegrid-slots tr:first-of-type .fc-timegrid-slot.fc-timegrid-slot-lane':
      {
        borderTop: '2px solid #EBEBEB',
        borderLeft: '2px solid #EBEBEB',
      },
    '.fc-timegrid-slots tr:last-of-type .fc-timegrid-slot.fc-timegrid-slot-lane':
      {
        border: 'none',
      },
    '.fc-timegrid-slot-label .fc-timegrid-slot-label-cushion': {
      p: 0,
    },
    '.fc-timegrid-now-indicator-arrow': {
      transform: 'translateX(2.75rem)',
    },
  },
})

export const TimeCalendar = defineMultiStyleConfig({
  baseStyle,
})
