import { z } from 'zod'

/**
 * @deprecated Use `postalCodeSchemaBuilder` instead
 */
export const postalCodeSchema = z
  .string()
  .length(6)
  .regex(/^[0-9]+$/)

export const postalCodeSchemaBuilder = {
  optional() {
    return z
      .string()
      .trim()
      .optional()
      .transform((v) => (v === '' ? undefined : v))
      .pipe(
        z
          .string()
          .length(6, 'Postal code must consist of 6 digits')
          .regex(/^[0-9]+$/, 'Postal code must consist of 6 digits')
          .or(z.undefined()),
      )
  },
  nullable() {
    return z
      .string()
      .trim()
      .nullable()
      .transform((v) => (v === '' ? null : v))
      .pipe(
        z
          .string()
          .length(6, 'Postal code must consist of 6 digits')
          .regex(/^[0-9]+$/, 'Postal code must consist of 6 digits')
          .or(z.null()),
      )
  },
  nullish() {
    return z
      .string()
      .trim()
      .nullish()
      .transform((v) => (v === '' ? undefined : v))
      .pipe(
        z
          .string()
          .length(6, 'Postal code must consist of 6 digits')
          .regex(/^[0-9]+$/, 'Postal code must consist of 6 digits')
          .or(z.null())
          .or(z.undefined()),
      )
  },
  required() {
    return z
      .string()
      .trim()
      .length(6, 'Postal code must consist of 6 digits')
      .regex(/^[0-9]+$/, 'Postal code must consist of 6 digits')
  },
}
