import { type SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

const _VerticalDivider = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="100%"
    viewBox="0 0 1 112"
    width="1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      stroke="#BFC2C8"
      strokeDasharray="2 2"
      x1="0.5"
      x2="0.499995"
      y1="2.18561e-08"
      y2="112"
    />
  </svg>
)
export const VerticalDivider = chakra(_VerticalDivider)
