import { type SpaceProps } from '@chakra-ui/react'

export const APP_GRID_TEMPLATE_COLUMN = {
  base: 'repeat(4, 1fr)',
  md: 'repeat(10, 1fr)',
}

export const APP_GRID_COLUMN = { base: '1 / 5', md: '1 / 12', lg: '3 / 9' }

export const APP_RESPONSIVE_PADDING_X: SpaceProps['px'] = {
  base: '1rem',
  md: '2rem',
  lg: 0,
}

export const APP_CONTENT_PADDING_Y: {
  pt: SpaceProps['pt']
  pb: SpaceProps['pb']
} = { pt: '1.5rem', pb: '2rem' }

export const NEGATE_APP_RESPONSIVE_PADDING_X: SpaceProps['mx'] = {
  base: '-1rem',
  md: '-2rem',
  lg: 0,
}
