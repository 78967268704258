import { createContext, useCallback, useReducer, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useInterval } from 'usehooks-ts'

import { useZodForm } from '~common/lib/form'

import { trpc } from '~/utils/trpc'
import { useMe } from '~/features/me/api'
import { updateMobileSchema, verifyMobileOtpSchema } from '~/schemas/settings'
import { getMobileVfnHumanReadableError } from '../../errorMapping'
import { defaultOtpState, OtpMachineEvent, otpStateReducer } from '../../state'

export interface VfnStepData {
  mobile: string
}

const RESEND_TIMER_DELAY = 60

export const useProvideVerifyMobileContext = (resetOnSuccess = false) => {
  const { me } = useMe()
  const toast = useToast({ isClosable: true })
  const [vfnStepData, setVfnStepData] = useState<VfnStepData>()
  const [resendTimer, setResendTimer] = useState(RESEND_TIMER_DELAY)

  const [state, dispatch] = useReducer(otpStateReducer, defaultOtpState)

  useInterval(
    () => setResendTimer((timer) => timer - 1),
    // Stop interval if timer hits 0, else rerun every 1000ms.
    !!vfnStepData && resendTimer > 0 ? 1000 : null,
  )

  const mobileFormProps = useZodForm({
    schema: updateMobileSchema,
    defaultValues: {
      mobile: me.mobile ?? '',
    },
  })

  const otpFormProps = useZodForm({
    schema: verifyMobileOtpSchema,
  })

  const sendOtpMutation = trpc.preferences.sendMobileOtp.useMutation({
    onSuccess: (vfnStepData) => {
      setVfnStepData(vfnStepData)
      setResendTimer(RESEND_TIMER_DELAY)
      otpFormProps.setValue('mobile', vfnStepData.mobile)
      dispatch(OtpMachineEvent.SendOtpSuccess)
    },
    onError: (error) => {
      mobileFormProps.setError(
        'mobile',
        { message: getMobileVfnHumanReadableError(error.message) },
        { shouldFocus: true },
      )
    },
  })

  /** Used to reset OTP form state to initial state and OTP Machine state to idle */
  const handleResetOtp = useCallback(
    (updatedMobile: string) => {
      mobileFormProps.reset({ mobile: updatedMobile })
      otpFormProps.reset({ mobile: undefined, token: undefined })
      dispatch(OtpMachineEvent.ResetToIdle)
    },
    [mobileFormProps, otpFormProps],
  )

  const verifyOtpMutation = trpc.preferences.verifyMobileOtp.useMutation({
    onSuccess: ({ mobile: updatedMobile }) => {
      dispatch(OtpMachineEvent.VerifyOtpSuccess)
      if (resetOnSuccess) {
        handleResetOtp(updatedMobile ?? '')
      }
      toast({
        description: 'Mobile number successfully updated',
        status: 'success',
      })
    },
    onError: (error) => {
      otpFormProps.setError(
        'token',
        { message: getMobileVfnHumanReadableError(error.message) },
        { shouldFocus: true },
      )
      toast({
        description: getMobileVfnHumanReadableError(error.message),
        status: 'error',
      })
    },
  })

  const handleSendOtp = mobileFormProps.handleSubmit(({ mobile }) =>
    sendOtpMutation.mutate({ mobile }),
  )

  const handleResendOtp = useCallback(() => {
    if (resendTimer > 0 || !vfnStepData) return
    return sendOtpMutation.mutate(
      { mobile: vfnStepData.mobile },
      {
        onSuccess: (vfnStepData) => {
          setVfnStepData(vfnStepData)
          otpFormProps.resetField('token')
          otpFormProps.setFocus('token')
          // On success, restart the timer before this can be called again.
          setResendTimer(RESEND_TIMER_DELAY)
          return
        },
      },
    )
  }, [otpFormProps, resendTimer, sendOtpMutation, vfnStepData])

  const handleVerifyOtp = otpFormProps.handleSubmit(({ mobile, token }) => {
    return verifyOtpMutation.mutate({ mobile, token })
  })

  return {
    state,
    mobileFormProps,
    otpFormProps,
    handleSendOtp,
    handleVerifyOtp,
    handleResendOtp,
    vfnStepData,
    isLoading: sendOtpMutation.isLoading || verifyOtpMutation.isLoading,
    isVerifyOtpLoading: verifyOtpMutation.isLoading,
    isSendOtpLoading: sendOtpMutation.isLoading,
    resendTimer,
  }
}

export type VerifyMobileContextReturn = ReturnType<
  typeof useProvideVerifyMobileContext
>

export const VerifyMobileContext = createContext<
  VerifyMobileContextReturn | undefined
>(undefined)
