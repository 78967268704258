import { type CUSTOM_ERROR_CODE_KEY } from '~common/constants/errors'
import { type Logger, type ScopedLogger } from '~common/server/logger'

import { CustomError } from './error'

export function trpcAssert<T>(
  condition: T extends Promise<unknown> ? never : T,
  opts: {
    message: string
    code: CUSTOM_ERROR_CODE_KEY
  } & (
    | {
        context: {
          action?: never
          [key: string]: unknown
        }
        logger: ScopedLogger
      }
    | {
        action: string
        context: {
          action?: never
          [key: string]: unknown
        }
        logger: Logger
      }
    | { context?: never; logger?: never }
  ),
): asserts condition {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!condition) {
    if ('action' in opts) {
      opts.logger.error({
        message: opts.message,
        action: opts.action,
        context: {
          code: opts.code,
          ...opts.context,
        },
      })
    } else if ('context' in opts && opts.logger) {
      opts.logger.error({
        message: opts.message,
        context: {
          code: opts.code,
          ...opts.context,
        },
      })
    }
    throw new CustomError({
      code: opts.code,
      message: opts.message,
    })
  }
}
