import { z } from 'zod'

import { isRelativeUrl } from '..'

export const redirectUrlSchema = z
  .string()
  .refine((url) => url && isRelativeUrl(url))

export const createRedirectUrlSchema = (base: string) =>
  redirectUrlSchema.catch(base)
