import { defineStyle } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { getContrastColor } from '@opengovsg/design-system-react'

const variantSolid = defineStyle((props) => {
  const { colorScheme: c, theme } = props

  const solidBgTokenMap: Record<string, string> = {
    main: 'brand.primary.500',
    sub: 'interaction.sub.default',
    neutral: 'interaction.neutral.default',
    info: 'utility.feedback.info',
    success: 'utility.feedback.success',
    warning: 'utility.feedback.warning',
    critical: 'utility.feedback.critical',
  }

  const bgColor = getColor(theme, solidBgTokenMap[c] ?? `${c}.500`) as string
  let textColor = getColor(theme, 'base.content.inverse') as string

  textColor = getContrastColor(textColor, bgColor, 'base.content.default')

  return {
    bg: bgColor,
    color: textColor,
  }
})

const variantSubtle = defineStyle((props) => {
  const { colorScheme: c } = props

  const subtleBgTokenMap: Record<string, string> = {
    main: 'interaction.main-subtle.default',
    sub: 'interaction.sub-subtle.default',
    success: 'utility.feedback.success-subtle',
    neutral: 'interaction.neutral-subtle.default',
    info: 'utility.feedback.info-subtle',
    warning: 'utility.feedback.warning-subtle',
    critical: 'utility.feedback.critical-subtle',
  }
  const subtleColorTokenMap: Record<string, string> = {
    main: 'interaction.main.default',
    sub: 'interaction.sub.default',
    success: 'utility.feedback.success',
    neutral: 'interaction.neutral.default',
    info: 'utility.feedback.info',
    warning: 'base.content.default',
    critical: 'utility.feedback.critical',
  }

  return {
    bg: subtleBgTokenMap[c] ?? `${c}.100`,
    color: subtleColorTokenMap[c] ?? `${c}.500`,
  }
})

const variantClear = defineStyle(({ colorScheme: c }) => {
  const clearIconColorTokenMap: Record<string, string> = {
    main: 'brand.primary.500',
    success: 'utility.feedback.success',
    warning: 'utility.feedback.warning',
    critical: 'utility.feedback.critical',
  }

  return {
    fontWeight: 400,
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    letterSpacing: 0,
    fontFamily: 'body',
    color: 'base.content.default',
    accentColor: clearIconColorTokenMap[c] ?? `${c}.500`,
  }
})

const variantOutline = defineStyle(({ colorScheme: c }) => {
  const colorTokenMap: Record<string, string> = {
    main: 'brand.primary.500',
    sub: 'brand.secondary.500',
    neutral: 'grey.500',
    success: 'green.500',
    info: 'grey.500',
    warning: 'yellow.700',
    critical: 'red.500',
  }

  const bgColorTokenMap: Record<string, string> = {
    main: 'brand.primary.50',
    sub: 'brand.secondary.50',
    neutral: 'grey.50',
    success: 'green.50',
    info: 'grey.50',
    warning: 'yellow.50',
    critical: 'red.50',
  }

  const borderColorTokenMap: Record<string, string> = {
    main: 'brand.primary.100',
    sub: 'brand.secondary.100',
    neutral: 'grey.100',
    success: 'green.100',
    info: 'grey.100',
    warning: 'yellow.100',
    critical: 'red.100',
  }

  return {
    textStyle: 'caption-1',
    color: colorTokenMap[c] ?? `${c}.500`,
    bg: bgColorTokenMap[c] ?? `${c}.50`,
    borderWidth: '1px',
    borderColor: borderColorTokenMap[c] ?? `${c}.100`,
    boxShadow: 'none',
  }
})

const variants = {
  solid: variantSolid,
  subtle: variantSubtle,
  clear: variantClear,
  outline: variantOutline,
}

export const Badge = {
  variants,
}
