import bigInt from 'big-integer'

import { divideAndRoundOff } from '~common/utils'

export const RECEIVABLES_CLEARING_ACCOUNT = 1
export const DEFERRED_REVENUE = 2
export const OUTPUT_GST = 3
export const BANK_CHARGES = 4 // basically Stripe fees
export const RENTAL_INCOME = 5
export const BANK = 6 // bank balance holding cash
export const CREDITORS_CLEARING_ACCOUNT = 7 // payable account
export const TRADE_DEBTORS = 8
export const INPUT_GST = 9
export const OTHER_CREDITORS = 10
export const SERVICES_RENDERED_REVENUE_CCY = 11
export const MISC_REVENUE = 12

/* When calculating GST amount, we typically work backwards from the total amount including GST.
  As such, to calculate the pre-GST amount, we divide the total amount by 1 + GST rate.
  To do this in bigInt, the divisor is 1 + GST rate, and the multiplier is 100.
 */
const getGstRate = (year: number) => {
  const divisor = year >= 2024 ? bigInt(109) : bigInt(108)
  const multiplier = bigInt(100)
  return { divisor, multiplier }
}

export const getPreGstAmount = (
  totalAmount: bigInt.BigInteger,
  year: number,
) => {
  const { divisor, multiplier } = getGstRate(year)
  return divideAndRoundOff({
    num: totalAmount,
    divisor,
    multiplier,
  })
}
