export const ACTIVE_SG_PROGRAMME_TYPES = {
  ACADEMIES_AND_CLUBS: 'ACADEMIES_AND_CLUBS',
  ACTIVE_HEALTH: 'ACTIVE_HEALTH',
  LEARN_TO_PLAY: 'LEARN_TO_PLAY',
  SPORTS_EDUCATION_PROGRAMME: 'SPORTS_EDUCATION_PROGRAMME',
  SPORTS_CARES: 'SPORTS_CARES',
} as const

export type ActiveSgProgrammeType = keyof typeof ACTIVE_SG_PROGRAMME_TYPES
export const PROGRAMME_INTENSITY_LEVEL = {
  Low: 'Low',
  Moderate: 'Moderate',
  Vigorous: 'Vigorous',
} as const

export const isProgrammeIntensityLevel = (
  value: unknown,
): value is ProgrammeIntensityLevel => {
  return Object.keys(PROGRAMME_INTENSITY_LEVEL).includes(value as string)
}

export type ProgrammeIntensityLevel = keyof typeof PROGRAMME_INTENSITY_LEVEL

export const PROGRAMME_STATUS = {
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
  CANCELLED: 'CANCELLED',
  ALL: 'ALL',
} as const
