import { createContext, useCallback, type PropsWithChildren } from 'react'

import { LOGGED_IN_KEY } from '~common/constants'
import { useLocalStorage } from '~common/hooks'

export interface LoginStateContextReturn {
  hasLoginStateFlag?: boolean
  setHasLoginStateFlag: () => void
  removeLoginStateFlag: () => void
}

// Exported for testing.
export const LoginStateContext = createContext<LoginStateContextReturn | null>(
  null,
)

/**
 * Provider component that wraps your app and makes client login state boolean available
 * to any child component that calls `useLoginState()`.
 */
export const LoginStateProvider = ({ children }: PropsWithChildren) => {
  const loginState = useProvideLoginState()

  return (
    <LoginStateContext.Provider value={loginState}>
      {children}
    </LoginStateContext.Provider>
  )
}

const useProvideLoginState = () => {
  const [hasLoginStateFlag, setLoginStateFlag] = useLocalStorage<boolean>(
    LOGGED_IN_KEY,
    undefined,
  )

  const setHasLoginStateFlag = useCallback(() => {
    setLoginStateFlag(true)
  }, [setLoginStateFlag])

  const removeLoginStateFlag = useCallback(() => {
    setLoginStateFlag(undefined)
  }, [setLoginStateFlag])

  return {
    hasLoginStateFlag: !!hasLoginStateFlag,
    setHasLoginStateFlag,
    removeLoginStateFlag,
  }
}
