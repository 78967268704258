import { useMemo, useRef } from 'react'
import { debounce } from 'lodash'

interface UseScrollToRefArgs {
  enabled: boolean
}

export const useScrollToRef = ({ enabled }: UseScrollToRefArgs) => {
  const scrollRef = useRef<HTMLInputElement | null>(null)

  const rescroll = useMemo(
    () =>
      debounce(
        () => {
          if (enabled) {
            setTimeout(
              () => scrollRef.current?.scrollIntoView({ behavior: 'smooth' }),
              100, // wait for the keyboard to open
            )
          }
        },
        500,
        { leading: true, trailing: false },
      ),
    [enabled],
  )

  return {
    scrollRef,
    rescroll,
  }
}
