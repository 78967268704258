const readOnlyOutline = {
  field: {
    _readOnly: {
      bg: 'interaction.support.disabled',
      borderColor: 'base.divider.strong',
      cursor: 'not-allowed',
      opacity: 1,
      _focus: {
        borderColor: 'base.divider.strong',
      },
    },
  },
}

export const NumberInput = {
  variants: {
    outline: readOnlyOutline,
  },
}
