import { type PropsWithChildren } from 'react'
import {
  CardBody,
  CardHeader,
  HStack,
  Icon,
  Text,
  type As,
} from '@chakra-ui/react'

import { InformationalCard } from './InfomationalCard'

export interface InfoSectionProps extends PropsWithChildren {
  label?: string
  labelIcon?: As
}

export const InfoSection = ({
  label,
  labelIcon,
  children,
}: InfoSectionProps): JSX.Element => {
  return (
    <InformationalCard>
      {label && (
        <CardHeader>
          <HStack color="base.content.default" textStyle="subhead-3">
            {labelIcon && <Icon as={labelIcon} fontSize="1rem" />}
            <Text>{label}</Text>
          </HStack>
        </CardHeader>
      )}
      <CardBody>{children}</CardBody>
    </InformationalCard>
  )
}
