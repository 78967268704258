import { useCallback, useEffect, useMemo } from 'react'
import Router from 'next/router'
import { useQueryClient } from '@tanstack/react-query'

import { useLoginState } from '~common/hooks'
import { setMonitoringUserContext } from '~common/lib/datadog'
import { gb } from '~common/lib/growthbook/client'
import { displayCurrency } from '~common/utils'

import { trpc } from '~/utils/trpc'

export const useMe = () => {
  const [me] = trpc.me.get.useSuspenseQuery()
  const queryClient = useQueryClient()

  useEffect(() => {
    gb.setAttributes({
      id: me.id,
    })
  }, [me])

  const myAsgDollars = useMemo(
    () => displayCurrency(me.credits?.balanceInCents ?? '0'),
    [me],
  )

  const { removeLoginStateFlag } = useLoginState()

  const logoutMutation = trpc.auth.logout.useMutation()

  const logout = useCallback(
    (redirectToSignIn = true) => {
      return logoutMutation.mutate(undefined, {
        onSuccess: () => {
          removeLoginStateFlag()
          queryClient.removeQueries()
          if (redirectToSignIn) {
            void Router.push('/sign-in')
          }
        },
      })
    },
    [logoutMutation, removeLoginStateFlag, queryClient],
  )

  setMonitoringUserContext({ id: me.id })

  return { me, myAsgDollars, logout }
}
