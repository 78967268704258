import { createContext, useEffect, type PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import { GrowthBookProvider } from '@growthbook/growthbook-react'

import { gb, updateGrowthBookURL } from '~common/lib/growthbook/client'
import { type GrowthbookProfile } from '~common/lib/growthbook/schemas'

export interface GrowthbookContextReturn {
  profile: GrowthbookProfile
}

export const GrowthbookContext = createContext<
  GrowthbookContextReturn | undefined
>(undefined)

export const AppGrowthbookProvider = ({
  children,
  profile,
}: PropsWithChildren<GrowthbookContextReturn>): JSX.Element => {
  const router = useRouter()

  useEffect(() => {
    // Load features from the GrowthBook API and keep them up-to-date
    void gb.loadFeatures({ autoRefresh: true })
    gb.setAttributes({ profile })

    // Subscribe to route change events and update GrowthBook
    router.events.on('routeChangeComplete', updateGrowthBookURL)
    return () => router.events.off('routeChangeComplete', updateGrowthBookURL)
  }, [profile, router.events])

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>
}
