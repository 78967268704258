import { type PropsWithChildren } from 'react'
import { Card, type CardProps } from '@chakra-ui/react'

export const InformationalCard = ({
  children,
  ...props
}: PropsWithChildren<CardProps>) => {
  return (
    <Card
      _active={{}}
      _focus={{}}
      _selected={{}}
      sx={{ '@media(hover: hover)': { _hover: {} } }}
      {...props}
    >
      {children}
    </Card>
  )
}
