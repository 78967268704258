import { env } from '@activesg/env'

export const TIMEZONE = 'Asia/Singapore'

const START_ACTIVESG_CREDITS_REFUND_TIME = new Date(
  '2024-07-01T00:00:00+08:00',
).valueOf()

export const shouldRefundActivesgCredits = () => {
  return (
    new Date().valueOf() >= START_ACTIVESG_CREDITS_REFUND_TIME ||
    env.NEXT_PUBLIC_ENVIRONMENT !== 'production'
  )
}
