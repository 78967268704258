// Adapted from https://github.com/devfolioco/react-otp-input/blob/main/src/index.tsx
// Renamed to PinInput

import {
  Fragment,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type ClipboardEvent,
  type FocusEvent,
  type KeyboardEvent,
  type ReactNode,
  type RefCallback,
} from 'react'
import { Flex, forwardRef, Stack, type FlexProps } from '@chakra-ui/react'
import { type InputProps } from '@opengovsg/design-system-react'

export type AllowedInputTypes = 'password' | 'text' | 'number' | 'tel'

export type InputElementProps = Omit<InputProps, 'type'> & {
  ref: RefCallback<HTMLInputElement>
  placeholder: string | undefined
  type: AllowedInputTypes
}

export interface PinInputProps {
  /** Value of the OTP input */
  value?: string
  /** Number of OTP inputs to be rendered */
  numInputs?: number
  /** Callback to be called when the OTP value changes */
  onChange: (otp: string) => void
  /** Callback to be called when pasting content into the component */
  onPaste?: (event: ClipboardEvent<HTMLDivElement>) => void
  /** Function to render the input */
  renderInput: (inputProps: InputElementProps, index: number) => ReactNode
  /** Whether the first input should be auto focused */
  shouldAutoFocus?: boolean
  /** Placeholder for the inputs */
  placeholder?: string
  /** Props for the container */
  containerProps?: FlexProps
  /** Style for the input */
  inputProps?: Omit<InputProps, 'type'>
  /** The type that will be passed to the input being rendered */
  inputType?: AllowedInputTypes
}

export const PinInput = forwardRef<PinInputProps, 'input'>(
  (
    {
      value = '',
      numInputs = 4,
      onChange,
      onPaste,
      renderInput,
      shouldAutoFocus = false,
      inputType = 'text',
      placeholder,
      containerProps,
      inputProps,
    },
    ref,
  ) => {
    const [activeInput, setActiveInput] = useState(0)
    const inputRefs = useRef<(HTMLInputElement | null)[]>([])

    const getOTPValue = () => (value ? value.toString().split('') : [])

    const isInputNum = inputType === 'number' || inputType === 'tel'

    useEffect(() => {
      inputRefs.current = inputRefs.current.slice(0, numInputs)
    }, [numInputs])

    useEffect(() => {
      if (shouldAutoFocus) {
        inputRefs.current[0]?.focus()
      }
    }, [shouldAutoFocus])

    const getPlaceholderValue = () => {
      if (typeof placeholder === 'string') {
        if (placeholder.length === numInputs) {
          return placeholder
        }

        if (placeholder.length > 0) {
          console.error(
            'Length of the placeholder should be equal to the number of inputs.',
          )
        }
      }
      return undefined
    }

    const isInputValueValid = (value: string) => {
      const isTypeValid = isInputNum
        ? !isNaN(Number(value))
        : typeof value === 'string'
      return isTypeValid && value.trim().length === 1
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (isInputValueValid(value)) {
        changeCodeAtFocus(value)
        focusInput(Math.min(getOTPValue().length, activeInput) + 1)
      }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { nativeEvent } = event
      const value = event.target.value

      if (!isInputValueValid(value)) {
        // Pasting from the native autofill suggestion on a mobile device can pass
        // the pasted string as one long input to one of the cells. This ensures
        // that we handle the full input and not just the first character.
        if (value.length === numInputs) {
          const hasInvalidInput = value
            .split('')
            .some((cellInput) => !isInputValueValid(cellInput))
          if (!hasInvalidInput) {
            handleOTPChange(value.split(''))
            focusInput(numInputs - 1)
          }
        }

        if (
          // @ts-expect-error - This was added previously to handle and edge case
          // for dealing with keyCode "229 Unidentified" on Android. Check if this is
          // still needed.
          nativeEvent.data === null &&
          // @ts-expect-error - As above
          nativeEvent.inputType === 'deleteContentBackward'
        ) {
          event.preventDefault()
          changeCodeAtFocus('')
          focusInput(activeInput - 1)
        }

        // Clear the input if it's not valid value because firefox allows
        // pasting non-numeric characters in a number type input
        event.target.value = ''
      }
    }

    const handleFocus =
      (event: FocusEvent<HTMLInputElement>) => (index: number) => {
        setActiveInput(index)
        event.target.select()
      }

    const handleBlur = () => {
      setActiveInput(activeInput - 1)
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      const otp = getOTPValue()
      if ([event.code, event.key].includes('Backspace')) {
        event.preventDefault()
        changeCodeAtFocus('')
        focusInput(activeInput - 1)
      } else if (event.code === 'Delete') {
        event.preventDefault()
        changeCodeAtFocus('')
      } else if (event.code === 'ArrowLeft') {
        event.preventDefault()
        focusInput(activeInput - 1)
      } else if (event.code === 'ArrowRight') {
        event.preventDefault()
        focusInput(activeInput + 1)
      }
      // React does not trigger onChange when the same value is entered
      // again. So we need to focus the next input manually in this case.
      else if (event.key === otp[activeInput]) {
        event.preventDefault()
        focusInput(activeInput + 1)
      } else if (
        event.code === 'Spacebar' ||
        event.code === 'Space' ||
        event.code === 'ArrowUp' ||
        event.code === 'ArrowDown'
      ) {
        event.preventDefault()
      }
    }

    const focusInput = (index: number) => {
      const activeInput = Math.max(Math.min(numInputs - 1, index), 0)

      if (inputRefs.current[activeInput]) {
        inputRefs.current[activeInput]?.focus()
        inputRefs.current[activeInput]?.select()
        setActiveInput(activeInput)
      }
    }

    const changeCodeAtFocus = (value: string) => {
      const otp = getOTPValue()
      // @ts-expect-error - This must be a valid index
      otp[activeInput] = value[0]
      handleOTPChange(otp)
    }

    const handleOTPChange = (otp: string[]) => {
      const otpValue = otp.join('')
      onChange(otpValue)
    }

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault()

      const otp = getOTPValue()
      let nextActiveInput = activeInput

      // Get pastedData in an array of max size (num of inputs - current position)
      const pastedData = event.clipboardData
        .getData('text/plain')
        .slice(0, numInputs - activeInput)
        .split('')

      // Prevent pasting if the clipboard data contains non-numeric values for number inputs
      if (isInputNum && pastedData.some((value) => isNaN(Number(value)))) {
        return
      }

      // Paste data from focused input onwards
      for (let pos = 0; pos < numInputs; ++pos) {
        if (pos >= activeInput && pastedData.length > 0) {
          otp[pos] = pastedData.shift() ?? ''
          nextActiveInput++
        }
      }

      focusInput(nextActiveInput)
      handleOTPChange(otp)
    }

    return (
      <Flex align="center" onPasteCapture={onPaste} {...containerProps}>
        <Stack direction="row" spacing={{ base: '0.25rem', md: '0.5rem' }}>
          {Array.from({ length: numInputs }, (_, index) => index).map(
            (index) => (
              <Fragment key={index}>
                {renderInput(
                  {
                    value: getOTPValue()[index] ?? '',
                    placeholder: getPlaceholderValue()?.[index] ?? undefined,
                    ref: (element) => {
                      if (index === 0) {
                        // @ts-expect-error - for compatibility with react hook form
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        inputRefs.current[index] = ref ? ref(element) : element
                      }
                      return (inputRefs.current[index] = element)
                    },
                    onChange: handleChange,
                    onFocus: (event) => handleFocus(event)(index),
                    onBlur: handleBlur,
                    onKeyDown: handleKeyDown,
                    onPaste: handlePaste,
                    autoComplete: 'off',
                    'aria-label': `Please enter OTP character ${index + 1}`,
                    inputMode: isInputNum ? 'numeric' : 'text',
                    onInput: handleInputChange,
                    type: inputType,
                    ...inputProps,
                  },
                  index,
                )}
              </Fragment>
            ),
          )}
        </Stack>
      </Flex>
    )
  },
)
