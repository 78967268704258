import { createElement } from 'react'
import { createStandaloneToast } from '@chakra-ui/react'
import { Toast } from '@opengovsg/design-system-react'

import { theme } from '~common/theme'

export const { toast, ToastContainer } = createStandaloneToast({
  theme,
  defaultOptions: {
    position: 'top',
    render: (props) => {
      return createElement(Toast, props)
    },
  },
})
