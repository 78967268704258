import { useCallback, useEffect, useRef } from 'react'
import NextLink from 'next/link'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Button, Link, useIsMobile } from '@opengovsg/design-system-react'

import { trpc } from '~/utils/trpc'
import { useMe } from '~/features/me/api'

export const AcceptTermsAndConditionsContent = (): JSX.Element | null => {
  const ref = useRef<HTMLButtonElement>(null)
  const isMobile = useIsMobile()
  const ctx = trpc.useContext()
  const { logout } = useMe()

  const { mutate } = trpc.me.acceptTermsAndConditions.useMutation({
    onSuccess: () => ctx.me.invalidate(),
  })

  useEffect(() => {
    if (!ref.current) {
      return
    }
    ref.current.focus()
  }, [])

  const onClick = useCallback(() => {
    mutate()
  }, [mutate])

  return (
    <>
      <ModalHeader>
        <Stack spacing="2.25rem">
          <Text pr="5rem">Accept Terms & Conditions</Text>
        </Stack>
      </ModalHeader>
      <ModalBody flex={1} whiteSpace="pre-wrap">
        By using the MyActiveSG+ website, you warrant that you have read, and
        that you accept and agree to be bound by the{' '}
        <Link
          as={NextLink}
          href="https://go.gov.sg/activesg-terms-of-use"
          target="_blank"
        >
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          as={NextLink}
          href="https://go.gov.sg/activesg-privacy-statement"
          target="_blank"
        >
          Privacy Policy
        </Link>
        .
      </ModalBody>
      <ModalFooter>
        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="end"
          spacing="0.5em"
          w="full"
        >
          <Button ref={ref} isFullWidth={isMobile} onClick={onClick}>
            Accept
          </Button>
          <Button
            colorScheme="sub"
            isFullWidth={isMobile}
            variant="clear"
            onClick={() => logout()}
          >
            Log Out
          </Button>
        </Stack>
      </ModalFooter>
    </>
  )
}
