import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const variantOutline = definePartsStyle({
  field: {
    _readOnly: {
      bg: 'interaction.support.disabled',
      borderColor: 'base.divider.strong',
      cursor: 'not-allowed',
      opacity: 1,
      _focus: {
        borderColor: 'base.divider.strong',
      },
    },
  },
  addon: {
    border: '1px solid',
    bg: 'interaction.support.disabled',
    borderColor: 'base.divider.strong',
  },
})

export const Input = defineMultiStyleConfig({
  variants: {
    outline: variantOutline,
  },
})
