import { type ReactNode } from 'react'
import { Icon, Stack, Text, type StackProps } from '@chakra-ui/react'
import { type IconType } from 'react-icons'

interface SectionHeaderProps extends StackProps {
  icon?: IconType
  description?: ReactNode
}

export const SectionHeader = ({
  icon,
  children,
  description,
  ...props
}: SectionHeaderProps) => (
  <Stack spacing={0}>
    <Stack
      align="center"
      color="base.content.default"
      direction="row"
      {...props}
    >
      {icon && <Icon aria-hidden as={icon} fontSize="1rem" />}
      <Text as="span" textStyle="subhead-3">
        {children}
      </Text>
    </Stack>
    {description}
  </Stack>
)
