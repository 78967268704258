import { createContext, useContext, type PropsWithChildren } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { useMe } from '~/features/me/api'

const UserOnboardingContext = createContext<
  | ({
      hasAcceptedTermsAndConditions: boolean
      hasMobile: boolean
    } & ReturnType<typeof useDisclosure>)
  | undefined
>(undefined)

export const useUserOnboarding = () => {
  const context = useContext(UserOnboardingContext)
  if (context === undefined) {
    throw new Error(
      'useUserOnboarding must be used within a UserOnboardingProvider',
    )
  }
  return context
}

export const UserOnboardingProvider = ({ children }: PropsWithChildren) => {
  const { me } = useMe()

  const disclosure = useDisclosure({
    defaultIsOpen: !me.acceptedTermsAndConditionsAt || !me.mobile,
  })

  return (
    <UserOnboardingContext.Provider
      value={{
        hasAcceptedTermsAndConditions: !!me.acceptedTermsAndConditionsAt,
        hasMobile: !!me.mobile,
        ...disclosure,
      }}
    >
      {children}
    </UserOnboardingContext.Provider>
  )
}
