import { type NextPageContext } from 'next'
import {
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
  TRPCClientError,
  type HTTPBatchLinkOptions,
  type HTTPLinkOptions,
  type TRPCLink,
} from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server'
import { observable } from '@trpc/server/observable'
import superjson from 'superjson'
import { z } from 'zod'

import { env } from '@activesg/env'
import {
  APP_VERSION_HEADER_KEY,
  IMITATE_USER_KEY,
  REQUIRE_UPDATE_EVENT,
} from '~common/constants'
import {
  BLACKLIST_ERROR_MESSAGE,
  type CUSTOM_ERROR_CODE_KEY,
} from '~common/constants/errors'
import {
  LOCAL_STORAGE_EVENT,
  LOGGED_IN_KEY,
} from '~common/constants/localStorage'
import { REDIRECT_URL_KEY } from '~common/constants/params'
import {
  getBaseUrl,
  safeSchemaJsonParse,
  TRPCWithErrorCodeSchema,
} from '~common/utils'

import { HOME, SUSPENDED, TERMINATED } from '~/constants/routes'
// ℹ️ Type-only import:
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export
import type { AppRouter } from '~/server/modules/appRouter'
import { IMITATE_USER_HEADER_KEY } from '../constants/imitate-user'

const NON_RETRYABLE_ERROR_CODES = new Set<CUSTOM_ERROR_CODE_KEY>([
  'BAD_REQUEST',
  'UNAUTHORIZED',
  'FORBIDDEN',
  'NOT_FOUND',
])

export const appVersionLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          if (!value.context) {
            return observer.next(value)
          }
          // TODO: Can this be typed better?
          const response = value.context.response as
            | Partial<Response> // Looser type for caution
            | undefined
          if (!response) {
            return observer.next(value)
          }
          const headers = response.headers
          if (!headers) {
            return observer.next(value)
          }
          const serverVersion = headers.get(APP_VERSION_HEADER_KEY)
          if (!serverVersion) {
            return observer.next(value)
          }
          const clientVersion = env.NEXT_PUBLIC_APP_VERSION
          if (clientVersion !== serverVersion) {
            window.dispatchEvent(new Event(REQUIRE_UPDATE_EVENT))
          }
          return observer.next(value)
        },
        error(err) {
          observer.error(err)
        },
        complete() {
          observer.complete()
        },
      })
      return unsubscribe
    })
  }
}

export const custom401Link: TRPCLink<AppRouter> = () => {
  // here we just got initialized in the app - this happens once per app
  // useful for storing cache for instance
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value)
        },
        // Handle 401 errors
        error(err) {
          observer.error(err)
          if (
            typeof window !== 'undefined' &&
            err.data?.code === 'UNAUTHORIZED'
          ) {
            // Clear logged in state on localStorage
            // NOTE: This error is not handled in the /api/[trpc] API route as API routes are invoked
            // on the server and cannot perform redirections.
            // We can think of this handler function as a form of client side auth validity
            // handling, and the /api/[trpc] API route as a form of server side auth validity handling.
            window.localStorage.removeItem(LOGGED_IN_KEY)
            window.dispatchEvent(new Event(LOCAL_STORAGE_EVENT))

            // Clear all imitation users so that stale local storage values from previous UATs dont cause infinite auth bugs
            // Only valid for NON-PROD TESTING ENVIRONMENTS
            window.localStorage.removeItem(IMITATE_USER_KEY)
          }
        },
        complete() {
          observer.complete()
        },
      })
      return unsubscribe
    })
  }
}

export const customBlacklistLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) =>
    observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value)
        },
        error(err) {
          if (
            err.data?.code === 'FORBIDDEN' &&
            (err.message === BLACKLIST_ERROR_MESSAGE.SUSPENDED ||
              err.message === BLACKLIST_ERROR_MESSAGE.TERMINATED)
          ) {
            handleRedirectToBlacklistPage(err.message)
            observer.complete()
          } else {
            observer.error(err)
          }
        },
        complete() {
          observer.complete()
        },
      })
      return unsubscribe
    })
}

const isErrorRetryableOnClient = (error: unknown): boolean => {
  if (typeof window === 'undefined') return true
  if (!(error instanceof TRPCClientError)) return true
  const res = TRPCWithErrorCodeSchema.safeParse(error)
  if (res.success && NON_RETRYABLE_ERROR_CODES.has(res.data)) return false
  return true
}

/**
 * Extend `NextPageContext` with meta data that can be picked up by `responseMeta()` when server-side rendering
 */
export interface SSRContext extends NextPageContext {
  /**
   * Set HTTP Status code
   * @example
   * const utils = trpc.useContext();
   * if (utils.ssrContext) {
   *   utils.ssrContext.status = 404;
   * }
   */
  status?: number
}

const handleRedirectToSignInPage = () => {
  if (typeof window === 'undefined') {
    return
  }

  if (window.location.pathname === '/sign-in') {
    return
  }

  const redirectUrl =
    window.location.pathname + window.location.search + window.location.hash
  const encodedRedirectUrl = encodeURIComponent(redirectUrl)

  // The choice to not redirect via next's router was intentional to handle ErrorBoundary for the app root
  // Using next's router.push('/sign-in') will not render the SignIn component as it won't be mounted in the app root as the ErrorBoundary fallback component will be rendered instead
  // Using vanilla location redirecting will prompt a full page reload of /sign-in page, which will never trigger the root ErrorBoundary, thus rendering the full component correctly
  window.location.href =
    redirectUrl === HOME
      ? `/sign-in`
      : `/sign-in?${REDIRECT_URL_KEY}=${encodedRedirectUrl}`
}

const handleRedirectToBlacklistPage = (blacklistType: string) => {
  if (typeof window === 'undefined') {
    return
  }

  if (
    window.location.pathname === SUSPENDED ||
    window.location.pathname === TERMINATED
  ) {
    return
  }

  if (blacklistType === BLACKLIST_ERROR_MESSAGE.SUSPENDED) {
    window.location.href = SUSPENDED
  } else if (blacklistType === BLACKLIST_ERROR_MESSAGE.TERMINATED) {
    window.location.href = TERMINATED
  }
}

const getHttpLinkOptions = (
  ctx: NextPageContext | undefined,
): HTTPLinkOptions & HTTPBatchLinkOptions => ({
  url: new URL('/api/trpc', getBaseUrl()).href,
  /**
   * Set custom request headers on every request from tRPC
   * @link https://trpc.io/docs/ssr
   */
  headers() {
    let imitateUserHeader: {
      [IMITATE_USER_HEADER_KEY]?: string
    } = {}

    if (env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
      const imitateUserId = safeSchemaJsonParse(
        z.string(),
        window.localStorage.getItem(IMITATE_USER_KEY) ?? '',
      )

      if (imitateUserId.success) {
        imitateUserHeader = {
          [IMITATE_USER_HEADER_KEY]: imitateUserId.data.replaceAll('"', ''),
        }
      }
    }

    if (ctx?.req) {
      // To use SSR properly, you need to forward the client's headers to the server
      // This is so you can pass through things like cookies when we're server-side rendering

      // If you're using Node 18, omit the "connection" header
      const { connection: _connection, ...headers } = ctx.req.headers
      return {
        ...headers,
        ...imitateUserHeader,
        [APP_VERSION_HEADER_KEY]: env.NEXT_PUBLIC_APP_VERSION,
        // Optional: inform server that it's an SSR request
        'x-ssr': '1',
      }
    }
    return {
      ...imitateUserHeader,
      [APP_VERSION_HEADER_KEY]: env.NEXT_PUBLIC_APP_VERSION,
    }
  },
  // Required for Datadog trace injection
  fetch: (...args) => fetch(...args),
})

/**
 * A set of strongly-typed React hooks from your `AppRouter` type signature with `createReactQueryHooks`.
 * @link https://trpc.io/docs/react#3-create-trpc-hooks
 */
export const trpc = createTRPCNext<AppRouter>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer: superjson,
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        appVersionLink,
        custom401Link,
        customBlacklistLink,
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        splitLink({
          condition: (op) => op.context.batch === true,
          true: httpBatchLink({
            ...getHttpLinkOptions(ctx),
            maxURLLength: 2083, // If not may get hit with 413 Request Entity Too Large or 414 URI Too Long
          }),
          false: httpLink(getHttpLinkOptions(ctx)),
        }),
      ],
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      queryClientConfig: {
        defaultOptions: {
          queries: {
            staleTime: 1000 * 10, // 10 seconds
            retry: (failureCount, error) => {
              if (!isErrorRetryableOnClient(error)) {
                return false
              }
              return failureCount < 3
            },
          },
          mutations: {
            retry: (_, error) => {
              if (error instanceof TRPCClientError) {
                const res = TRPCWithErrorCodeSchema.safeParse(error)
                if (
                  (res.success && res.data === 'UNAUTHORIZED') ||
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  error.data?.code === 'UNAUTHORIZED'
                ) {
                  handleRedirectToSignInPage()
                } else if (
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  error.data?.code === 'FORBIDDEN' &&
                  (error.message === BLACKLIST_ERROR_MESSAGE.SUSPENDED ||
                    error.message === BLACKLIST_ERROR_MESSAGE.TERMINATED)
                ) {
                  handleRedirectToBlacklistPage(error.message)
                }
              }

              return false
            },
          },
        },
      },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
  /**
   * Set headers or status code when doing SSR
   */
  // responseMeta(opts) {
  //   const ctx = opts.ctx as SSRContext;

  //   if (ctx.status) {
  //     // If HTTP status set, propagate that
  //     return {
  //       status: ctx.status,
  //     };
  //   }

  //   const error = opts.clientErrors[0];
  //   if (error) {
  //     // Propagate http first error from API calls
  //     return {
  //       status: error.data?.httpStatus ?? 500,
  //     };
  //   }

  //   // for app caching with SSR see https://trpc.io/docs/caching

  //   return {};
  // },
})

export type RouterInput = inferRouterInputs<AppRouter>
export type RouterOutput = inferRouterOutputs<AppRouter>
