import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

import { createRedirectUrlSchema, redirectUrlSchema } from '~common/schemas/url'

import { REDIRECT_URL_KEY } from '../constants/params'
import { useCurrentUrl } from './useCurrentUrl'

/**
 * This hooks redirects whilst storing the page before redirect in a query param.
 * The default behaviour is to use replace instead of push, unless the push param is set to true.
 * @param redirectTo - The page to redirect to
 * @param push - Whether to use push or replace.
 */
export const useRedirectWithState = (redirectTo: string, push?: boolean) => {
  const router = useRouter()
  const { encodedUrl } = useCurrentUrl()

  const safeUrl = redirectUrlSchema.safeParse(encodedUrl)

  const redirectUrl = useMemo(() => {
    if (!safeUrl.success) {
      return createRedirectUrlSchema('/').parse(redirectTo)
    }
    return `${redirectTo}?${REDIRECT_URL_KEY}=${encodedUrl}`
  }, [safeUrl, encodedUrl, redirectTo])

  const redirect = useCallback(() => {
    if (push) {
      return router.push(redirectUrl)
    }
    return router.replace(redirectUrl)
  }, [push, redirectUrl, router])

  return {
    redirect,
    redirectUrl,
  }
}
