export const FACILITY_BOOKING_ROUTES = {
  root: () => '/bookings',
  review: () => ({
    root: () => `${FACILITY_BOOKING_ROUTES.root()}/review`,
    paymentId: (paymentId: string) =>
      `${FACILITY_BOOKING_ROUTES.review().root()}/${paymentId}`,
  }),
  confirmation: () => ({
    root: () => `${FACILITY_BOOKING_ROUTES.root()}/confirmation`,
    paymentId: (paymentId: string) =>
      `${FACILITY_BOOKING_ROUTES.confirmation().root()}/${paymentId}`,
  }),
  myBookings: () => `${FACILITY_BOOKING_ROUTES.root()}/my-bookings`,
}

export const FACILITY_BALLOTING_ROUTES = {
  root: () => '/ballots',
  confirmation: () => ({
    root: () => `${FACILITY_BALLOTING_ROUTES.root()}/confirmation`,
    referenceId: (referenceId: string) =>
      `${FACILITY_BALLOTING_ROUTES.confirmation().root()}/${referenceId}`,
  }),
}
