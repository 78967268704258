import { useContext } from 'react'

import {
  LoginStateContext,
  type LoginStateContextReturn,
} from '~common/components/Providers/AuthWrappers'

/**
 * Hook for components nested in LoginStateProvider component to get the current login state.
 */
export const useLoginState = (): LoginStateContextReturn => {
  const context = useContext(LoginStateContext)
  if (!context) {
    throw new Error(
      'useLoginState must be used within a LoginStateProvider component',
    )
  }
  return context
}
