import { type z } from 'zod'

export const safeSchemaJsonParse = <T extends z.ZodTypeAny>(
  schema: T,
  jsonString: string,
  // eslint-disable-next-line no-restricted-syntax
  parse: (jsonString: string) => unknown = JSON.parse,
): { success: true; data: z.infer<T> } | { success: false; error: Error } => {
  try {
    const parsed = parse(jsonString)
    return schema.safeParse(parsed)
  } catch (e: unknown) {
    if (e instanceof Error) {
      return { success: false, error: e }
    }
    return { success: false, error: new Error(`Unknown JSON parse error`) }
  }
}
