import { useMemo } from 'react'
import { useRouter } from 'next/router'

export const useCurrentUrl = () => {
  const router = useRouter()

  const url = useMemo(() => {
    return router.isReady ? router.asPath : '/'
  }, [router])

  /**
   * current URL appended to the redirect query param.
   */
  const encodedUrl = useMemo(() => {
    return encodeURIComponent(url)
  }, [url])

  return {
    url,
    encodedUrl,
  }
}
