import { Component, useEffect } from 'react'

import { FullscreenSpinner } from '~common/components/FullscreenSpinner'
import { type CUSTOM_ERROR_CODE_KEY } from '~common/constants/errors'
import { useRedirectWithState } from '~common/hooks'
import { TRPCWithErrorCodeSchema } from '~common/utils/error'

import { trpc } from '~/utils/trpc'
import { SIGN_IN } from '~/constants/routes'
import {
  type ErrorBoundaryProps,
  type ErrorBoundaryState,
} from './ErrorBoundary.types'
import { ErrorCard } from './ErrorCard'

/**
 * Does the following:
 * 1. Checks if this is a recognizable TRPCClientError
 * 1a. Not TRPCClientError: render fallback component or UnexpectedErrorCard
 * 1b. Is TPRCClientError: redner fallback component or ErrorComponent
 *  - If Not Found, render 'Not Found' text
 *  - If Unauthorized, invalidate the trpc context and render FullscreenSpinner
 *  - If other error, render UnexpectedErrorCard
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error }
  }
  componentDidCatch(error: Error) {
    // You can use your own error logging service here
    console.error(error)
  }

  render() {
    const { children, fallback } = this.props
    const error = this.state.error

    // Check if the error is thrown
    if (!this.state.hasError) return children

    const res = TRPCWithErrorCodeSchema.safeParse(error)

    if (!res.success) {
      return (
        fallback ?? <ErrorCard title="Sorry, an unexpected error occurred" />
      )
    }
    return fallback ?? <ErrorComponent code={res.data} />
  }
}

const UnauthorizedErrorComponent = () => {
  const utils = trpc.useContext()
  const { redirectUrl } = useRedirectWithState(SIGN_IN)
  useEffect(() => {
    void utils.invalidate()

    // Error boundaries do not reset when using next's router, because
    // hasError state is still true in the parent `ErrorBoundary` component.
    // Thus we need to force a reload of the page to reset the state, by calling
    // `window.location.replace()`
    // To fix this, we can probably wrap the `ErrorBoundary` component with another
    // component that controls the state. See: https://dev.to/tylerlwsmith/error-boundary-causes-react-router-links-to-stop-working-50gb
    // Maybe using the `react-error-boundary` package will fix this too?
    window.location.replace(redirectUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FullscreenSpinner />
}

// TODO: Make custom components for these
function ErrorComponent({ code }: { code: CUSTOM_ERROR_CODE_KEY }) {
  switch (code) {
    case 'NOT_FOUND':
      return <ErrorCard title="Resource not found" />
    case 'UNAUTHORIZED':
      return <UnauthorizedErrorComponent />
    default:
      return <ErrorCard title="Sorry, an unexpected error occurred" />
  }
}
