import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { anatomy } from '@chakra-ui/theme-tools'

const parts = anatomy('infobox').parts('messagebox', 'icon')

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  messagebox: {
    display: 'flex',
    justifyContent: 'start',
    color: 'base.content.strong',
    borderRadius: 'md',
  },
})

const variantInfo = definePartsStyle({
  messagebox: {
    bg: 'utility.feedback.info-subtle',
    border: '1px solid',
    borderColor: 'utility.feedback.info',
  },
  icon: {
    color: 'utility.feedback.info',
  },
})

const variantWarning = definePartsStyle({
  messagebox: {
    bg: 'utility.feedback.warning-subtle',
    border: '1px solid',
    borderColor: 'utility.feedback.warning',
  },
  icon: {
    color: 'utility.feedback.warning',
  },
})

const variantError = definePartsStyle({
  messagebox: {
    bg: 'utility.feedback.critical-subtle',
    border: '1px solid',
    borderColor: 'utility.feedback.critical',
  },
  icon: {
    color: 'utility.feedback.critical',
  },
})

const variantSuccess = definePartsStyle({
  messagebox: {
    bg: 'utility.feedback.success-subtle',
    border: '1px solid',
    borderColor: 'utility.feedback.success',
  },
  icon: {
    color: 'utility.feedback.success',
  },
})

const variants = {
  info: variantInfo,
  warning: variantWarning,
  error: variantError,
  success: variantSuccess,
}

export const Infobox = defineMultiStyleConfig({
  baseStyle,
  variants,
})
