import { tableAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  type SystemStyleObject,
} from '@chakra-ui/react'

import { textStyles } from '../textStyles'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  tr: {
    pos: 'relative',
    textStyle: 'body-2',
  },
})

const sizes = {
  md: {
    th: {
      py: '1rem',
      ...textStyles['subhead-2'],
    },
    td: {
      p: '1rem',
    },
  },
}

const getSubtleVariantThStyles = (c: string): SystemStyleObject => {
  const baseStyles: SystemStyleObject = {
    color: 'grey.700',
    textTransform: 'initial',
  }

  return {
    bg: `interaction.${c}-subtle.default`,
    color: 'base.content.medium',
    ...baseStyles,
  }
}

const variantSubtle = definePartsStyle(({ colorScheme: c }) => {
  return {
    thead: {
      bg: `interaction.${c}-subtle.default`,
      opacity: 1,
      zIndex: 1,
    },
    th: getSubtleVariantThStyles(c),
    td: {
      color: 'base.content.default',
    },
  }
})

const variants = {
  subtle: variantSubtle,
}

export const Table = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'subtle',
    size: 'md',
    colorScheme: 'neutral',
  },
  sizes,
})
