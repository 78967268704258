import { z } from 'zod'

import {
  CUSTOM_ERROR_CODE,
  type CUSTOM_ERROR_CODE_KEY,
} from '~common/constants/errors'

const CUSTOM_ERROR_CODE_KEYS = Object.keys(CUSTOM_ERROR_CODE) as unknown as [
  CUSTOM_ERROR_CODE_KEY,
  ...CUSTOM_ERROR_CODE_KEY[],
]

export const TRPCWithErrorCodeSchema = z
  .object({
    data: z.object({ code: z.enum(CUSTOM_ERROR_CODE_KEYS) }),
  })
  .transform((data) => data.data.code)

export class CustomError extends Error {
  code: CUSTOM_ERROR_CODE_KEY
  context?: unknown
  location?: string
  cause?: Error | undefined

  constructor({
    context,
    location,
    code,
    ...opts
  }: {
    code: CUSTOM_ERROR_CODE_KEY
    context?: unknown
    location?: string
    message?: string
    cause?: unknown
  }) {
    const cause = getCauseFromUnknown(opts.cause)
    const message = opts.message ?? cause?.message ?? code

    super(message, { cause })

    this.name = this.constructor.name
    this.code = code
    this.context = context
    this.location = location
  }
}

class SyntheticError extends Error {
  [key: string]: unknown
}

function isObject(value: unknown): value is Record<string, unknown> {
  // check that value is object
  return !!value && !Array.isArray(value) && typeof value === 'object'
}

// Adapted from tRPC error handling
export function getCauseFromUnknown(cause: unknown): Error | undefined {
  if (cause instanceof Error) {
    return cause
  }

  const type = typeof cause
  if (type === 'undefined' || type === 'function' || cause === null) {
    return undefined
  }

  // Primitive types just get wrapped in an error
  if (type !== 'object') {
    return new Error(String(cause))
  }

  // If it's an object, we'll create a synthetic error
  if (isObject(cause)) {
    const err = new SyntheticError()
    for (const key in cause) {
      err[key] = cause[key]
    }
    return err
  }

  return undefined
}
