export const LedgerType = {
  ASSET: 'ASSET',
  LIABILITY: 'LIABILITY',
  REVENUE: 'REVENUE',
  EXPENSE: 'EXPENSE',
} as const
export type LedgerType = (typeof LedgerType)[keyof typeof LedgerType]
export const LedgerTransactionType = {
  COLLECTION: 'COLLECTION',
  COLLECTION_ON_BEHALF: 'COLLECTION_ON_BEHALF',
  EXCESS_COLLECTION: 'EXCESS_COLLECTION',
  REVENUE_RECOGNITION: 'REVENUE_RECOGNITION',
  PAYOUT: 'PAYOUT',
  REFUND: 'REFUND',
  EXCESS_REFUND: 'EXCESS_REFUND',
} as const
export type LedgerTransactionType =
  (typeof LedgerTransactionType)[keyof typeof LedgerTransactionType]
export const ActiveSgCreditTransactionType = {
  PAYMENT: 'PAYMENT',
  CREDIT: 'CREDIT',
  EXPIRED: 'EXPIRED',
  REFUND: 'REFUND',
  DEDUCTION: 'DEDUCTION',
} as const
export type ActiveSgCreditTransactionType =
  (typeof ActiveSgCreditTransactionType)[keyof typeof ActiveSgCreditTransactionType]
export const BookingStatus = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  CANCELLED_WITHOUT_REFUND: 'CANCELLED_WITHOUT_REFUND',
  CANCELLED_WITH_REFUND: 'CANCELLED_WITH_REFUND',
} as const
export type BookingStatus = (typeof BookingStatus)[keyof typeof BookingStatus]
export const BallotStatus = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  SELECTED: 'SELECTED',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
} as const
export type BallotStatus = (typeof BallotStatus)[keyof typeof BallotStatus]
export const FacilityStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const
export type FacilityStatus =
  (typeof FacilityStatus)[keyof typeof FacilityStatus]
export const UserStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const
export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus]
export const Sex = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  UNKNOWN: 'UNKNOWN',
} as const
export type Sex = (typeof Sex)[keyof typeof Sex]
export const ResidentialStatus = {
  ALIEN: 'ALIEN',
  CITIZEN: 'CITIZEN',
  PR: 'PR',
} as const
export type ResidentialStatus =
  (typeof ResidentialStatus)[keyof typeof ResidentialStatus]
export const BlacklistType = {
  SUSPENSION: 'SUSPENSION',
  TERMINATION: 'TERMINATION',
} as const
export type BlacklistType = (typeof BlacklistType)[keyof typeof BlacklistType]
export const TempPrincipalRole = {
  READ_PASS: 'READ_PASS',
  SUPER_ADMIN: 'SUPER_ADMIN',
} as const
export type TempPrincipalRole =
  (typeof TempPrincipalRole)[keyof typeof TempPrincipalRole]
export const MemberLinkRelation = {
  CHILD: 'CHILD',
  OTHER: 'OTHER',
} as const
export type MemberLinkRelation =
  (typeof MemberLinkRelation)[keyof typeof MemberLinkRelation]
export const MembershipStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const
export type MembershipStatus =
  (typeof MembershipStatus)[keyof typeof MembershipStatus]
export const TimeRestriction = {
  PEAK_ONLY: 'PEAK_ONLY',
  OFF_PEAK_ONLY: 'OFF_PEAK_ONLY',
  ALL: 'ALL',
} as const
export type TimeRestriction =
  (typeof TimeRestriction)[keyof typeof TimeRestriction]
export const PassCheckoutSessionStatus = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
} as const
export type PassCheckoutSessionStatus =
  (typeof PassCheckoutSessionStatus)[keyof typeof PassCheckoutSessionStatus]
export const PaymentStatus = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
} as const
export type PaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus]
export const RefundStatus = {
  REVIEWING: 'REVIEWING',
  CREATING: 'CREATING',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
} as const
export type RefundStatus = (typeof RefundStatus)[keyof typeof RefundStatus]
export const ProgrammeBookingStatus = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
} as const
export type ProgrammeBookingStatus =
  (typeof ProgrammeBookingStatus)[keyof typeof ProgrammeBookingStatus]
export const ProgrammeSessionBookingStatus = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  CANCELLED_WITH_REFUND: 'CANCELLED_WITH_REFUND',
  CANCELLED_WITHOUT_REFUND: 'CANCELLED_WITHOUT_REFUND',
} as const
export type ProgrammeSessionBookingStatus =
  (typeof ProgrammeSessionBookingStatus)[keyof typeof ProgrammeSessionBookingStatus]
export const ActivityStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const
export type ActivityStatus =
  (typeof ActivityStatus)[keyof typeof ActivityStatus]
export const NotificationChannel = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
} as const
export type NotificationChannel =
  (typeof NotificationChannel)[keyof typeof NotificationChannel]
export const NotificationKey = {
  BOOKING: 'BOOKING',
  PASS: 'PASS',
  PROGRAMME: 'PROGRAMME',
} as const
export type NotificationKey =
  (typeof NotificationKey)[keyof typeof NotificationKey]
export const SerialType = {
  TRANSACTION: 'TRANSACTION',
  RECEIPT: 'RECEIPT',
  REFUND: 'REFUND',
} as const
export type SerialType = (typeof SerialType)[keyof typeof SerialType]
