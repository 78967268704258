import '~common/utils/big-int/register'
import '@fontsource/ibm-plex-mono' // Import if using code textStyles.
import 'inter-ui/inter.css' // Strongly recommended.
import './turnstile.css' // To enable full width turnstile

import type { AppProps, AppType } from 'next/app'
import Head from 'next/head'
import { Skeleton, Stack } from '@chakra-ui/react'
import {
  RestrictedGovtMasthead,
  ThemeProvider,
} from '@opengovsg/design-system-react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { env } from '@activesg/env'
import {
  AppBanner,
  AppGrowthbookProvider,
  EnvProvider,
  Suspense,
} from '~common/components'
import { AppVersionModalBanner } from '~common/components/AppVersionModalBanner'
import { LoginStateProvider } from '~common/components/Providers/AuthWrappers'
import { initMonitoring } from '~common/lib/datadog'
import { ToastContainer } from '~common/lib/toast'
import { theme } from '~common/theme'
import { type NextPageWithLayout } from '~common/types'
import { getBaseUrl } from '~common/utils'

import { trpc } from '~/utils/trpc'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import { HOME, SUSPENDED, TERMINATED } from '~/constants/routes'
import { UserOnboardingModalChecker } from '~/features/settings/components/UserOnboardingModal'
import { DefaultLayout } from '~/templates/layouts/DefaultLayout'

type AppPropsWithAuthAndLayout = AppProps & {
  Component: NextPageWithLayout
}

// We use process.env instead of env since we will capture this at build time.
if (process.env.NODE_ENV === 'production') {
  initMonitoring({ service: 'activesg-member' })
}

const MyApp = ((props: AppPropsWithAuthAndLayout) => {
  const indexable =
    env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
    ['/', HOME].includes(props.router.pathname)
  const isIsolatedPage = [SUSPENDED, TERMINATED].includes(props.router.pathname)

  return (
    <>
      <Head>
        <title>{env.NEXT_PUBLIC_APP_NAME}</title>
        <meta
          content="Book sports facilities, join programmes, and purchase swim and gym passes with MyActiveSG+. With balloting for high demand peak hour slots and Singpass verification, there's more chances for everyone to book. Parents can link their children's accounts to book facilities, enrol them in programmes, and buy swim or gym passes for their children."
          name="description"
        />
        <link href="/favicon.ico" rel="icon" />
        <link
          href="/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />

        <link
          href="/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          href="/android-chrome-192x192.png"
          rel="icon"
          sizes="192x192"
          type="image/png"
        />
        <link
          href="/android-chrome-512x512.png"
          rel="icon"
          sizes="512x512"
          type="image/png"
        />
        <link
          href="/apple-touch-icon.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <meta content="ActiveSG" property="og:title" />
        <meta
          content={new URL('/og-image-1200x630.png', getBaseUrl()).href}
          property="og:image"
        />
        <meta content="https://activesg.gov.sg/" property="og:url" />
        <meta
          content="Book sports facilities, join programmes, and purchase swim and gym passes with MyActiveSG+. With balloting for high demand peak hour slots and Singpass verification, there's more chances for everyone to book. Parents can link their children's accounts to book facilities, enrol them in programmes, and buy swim or gym passes for their children."
          property="og:description"
        />
        <meta
          key="robots"
          content={`${indexable ? 'index' : 'noindex'},nofollow`}
          name="robots"
        />
      </Head>
      <EnvProvider env={env}>
        <LoginStateProvider>
          <AppGrowthbookProvider profile="PUBLIC">
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <Suspense fallback={<Skeleton height="100vh" width="100vw" />}>
                  <Stack minH="$100vh" spacing={0}>
                    <RestrictedGovtMasthead />
                    <AppVersionModalBanner />
                    {!isIsolatedPage && <UserOnboardingModalChecker />}
                    <AppBanner />
                    <ChildWithLayout {...props} />
                    {process.env.NODE_ENV !== 'production' && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                  </Stack>
                </Suspense>
              </ErrorBoundary>
            </ThemeProvider>
          </AppGrowthbookProvider>
        </LoginStateProvider>
      </EnvProvider>
      <ToastContainer />
    </>
  )
}) as AppType

// This is needed so suspense will be triggered for anything within the LayoutComponents which uses useSuspenseQuery
const ChildWithLayout = ({
  Component,
  pageProps,
}: AppPropsWithAuthAndLayout) => {
  const getLayout = Component.getLayout ?? DefaultLayout

  return <>{getLayout(<Component {...pageProps} />)}</>
}

export default trpc.withTRPC(MyApp)
