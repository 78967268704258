import { type TRPC_ERROR_CODE_KEY } from '@trpc/server/rpc'

export const CUSTOM_ERROR_CODE = {
  PARSE_ERROR: 400,
  BAD_REQUEST: 400,

  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_SUPPORTED: 405,
  TIMEOUT: 408,
  CONFLICT: 409,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  UNPROCESSABLE_CONTENT: 422,
  TOO_MANY_REQUESTS: 429,
  CLIENT_CLOSED_REQUEST: 499,

  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  SERVICE_UNAVAILABLE: 503,
} as const satisfies Record<CUSTOM_ERROR_CODE_KEY, number>

export const BLACKLIST_ERROR_MESSAGE = {
  TERMINATED: 'TERMINATED',
  SUSPENDED: 'SUSPENDED',
}

export type CUSTOM_ERROR_CODE_KEY = TRPC_ERROR_CODE_KEY | 'SERVICE_UNAVAILABLE'
