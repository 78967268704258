import bigInt, { type BigInteger } from 'big-integer'

/**
 * Returns value multiplied by multiplier divided by divisor, rounded off to the nearest integer.
 */
export function divideAndRoundOff({
  num,
  divisor,
  multiplier = bigInt(1),
}: {
  num: BigInteger
  divisor: BigInteger
  multiplier?: BigInteger
}) {
  if (divisor.equals(0)) {
    throw new Error('Cannot divide by 0')
  }
  num = num.multiply(multiplier)
  const { quotient, remainder } = num.divmod(divisor)
  // to make this precise to two decimal places, we multiply everything by 100
  // without this, e.g. if divisor is 3, half divisor would be 1 instead of 1.5
  const halfDivisor = divisor.multiply(100).divide(2)
  if (remainder.multiply(100).greaterOrEquals(halfDivisor)) {
    return quotient.add(1)
  } else {
    return quotient
  }
}

/**
 * Returns value multiplied by multiplier divided by divisor, rounded down to the nearest integer.
 */
export function divideAndRoundDown({
  num,
  divisor,
  multiplier = bigInt(1),
}: {
  num: BigInteger
  divisor: BigInteger
  multiplier?: BigInteger
}) {
  if (divisor.equals(0)) {
    throw new Error('Cannot divide by 0')
  }
  num = num.multiply(multiplier)
  const { quotient } = num.divmod(divisor)
  return quotient
}

export function splitEquallyAndBackloadRemainder(
  total: BigInteger,
  numSplits: BigInteger,
): {
  nonLastSplitAmount: BigInteger
  lastSplitAmount: BigInteger
} {
  if (numSplits.equals(0)) {
    throw new Error('Cannot split into 0 parts')
  }
  if (total.equals(0)) {
    return {
      nonLastSplitAmount: bigInt(0),
      lastSplitAmount: bigInt(0),
    }
  }
  const nonLastSplitAmount = divideAndRoundDown({
    num: total,
    divisor: numSplits,
  })
  const lastSplitAmount = total.subtract(
    nonLastSplitAmount.multiply(numSplits.subtract(1)),
  )
  if (lastSplitAmount.lesser(0)) {
    throw new Error('Last split amount cannot be negative')
  }
  return {
    nonLastSplitAmount,
    lastSplitAmount,
  }
}
