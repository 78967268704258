import {
  useBreakpointValue as useChakraBreakpointValue,
  type UseBreakpointOptions,
} from '@chakra-ui/react'

/**
 * Default useBreakpointValue to have `ssr: false` to prevent flash of
 * incorrect media query values when used.
 *
 * See https://chakra-ui.com/docs/hooks/use-breakpoint-value#usage
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBreakpointValue = <T = any>(
  value: Parameters<typeof useChakraBreakpointValue<T>>[0],
  args?: UseBreakpointOptions,
) => {
  return useChakraBreakpointValue<T>(value, {
    ssr: false,
    ...args,
  })
}
