import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { theme as ogpDsTheme } from '@opengovsg/design-system-react'
import { merge } from 'lodash'

import { components } from './components'
import { breakpoints } from './foundations/breakpoints'
import { colors } from './foundations/colors'
import { extendedColours } from './foundations/extendedColors'
import { shadows } from './foundations/shadows'
import { layerStyles } from './layerStyles'
import { textStyles } from './textStyles'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const commonComponents = {
  ...ogpDsTheme.components,
  ...components,
}

export const theme = extendTheme(
  ogpDsTheme,
  {
    styles: {
      global: {
        html: {
          background: 'base.canvas.default',
        },
      },
    },
    breakpoints,
    shadows,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    components: commonComponents,
    colors: merge(colors, extendedColours),
    sizes: {
      appNavbar: '3.5rem',
    },
    layerStyles,
    textStyles,
    fonts: {
      stamp: 'Dosis, monospace',
    },
  },
  withDefaultColorScheme({
    colorScheme: 'red',
    components: ['Link'],
  }),
)
