import {
  type Pass,
  type PassCheckoutSessionStatus,
  type PassType,
  type PassVariantAgeConfiguration,
  type PaymentStatus,
  type RefundStatus,
  type ResidentialStatus,
  type TimeRestriction,
} from '@activesg/db'

import { assertUnreachable } from './asserts'
import { calculateYearAge, ld } from './dates'
import { dayjs } from './dayjs'
import { toTitleCase } from './toTitleCase'

export function isEligibleForSeniorCitizenPass({
  dob,
  residentialStatus,
}: {
  dob: Date
  residentialStatus: ResidentialStatus | null
}) {
  return calculateYearAge(dob) >= 65 && residentialStatus === 'CITIZEN'
}

export function passAgeSortComparator<
  T extends { ageGroup: string; minAge: number | null; maxAge: number | null },
>(lhs: T, rhs: T) {
  // We do a best effort sort based on the age ranges.
  if (lhs.minAge && rhs.minAge) {
    return lhs.minAge - rhs.minAge
  }
  if (lhs.minAge && rhs.maxAge) {
    return lhs.minAge - rhs.maxAge
  }
  if (lhs.maxAge && rhs.minAge) {
    return lhs.maxAge - rhs.minAge
  }
  if (lhs.maxAge && rhs.maxAge) {
    return lhs.maxAge - rhs.maxAge
  }

  if (lhs.minAge || lhs.maxAge) {
    return 1
  }
  if (rhs.minAge || rhs.maxAge) {
    return -1
  }

  const agePriority: Record<string, number> = {
    Child: 1,
    Student: 1,
    Adult: 2,
    Senior: 3,
  }

  const maxAgePriority = Math.max(...Object.values(agePriority))

  return (
    (agePriority[lhs.ageGroup] ?? maxAgePriority) -
    (agePriority[rhs.ageGroup] ?? maxAgePriority)
  )
}

export function renderPassValidity(days: number) {
  return dayjs.duration(days, 'days').humanize().replace('a', '1')
}

function renderTimeRestriction(timeRestriction: TimeRestriction) {
  switch (timeRestriction) {
    case 'ALL':
      return 'Any Time'
    case 'PEAK_ONLY':
      return 'Peak'
    case 'OFF_PEAK_ONLY':
      return 'Off-Peak'
    default:
      return assertUnreachable(timeRestriction)
  }
}

export function renderTimeRestrictionLabel({
  timeRestriction,
  timeRestrictionLabel,
}: Pick<Pass, 'timeRestriction' | 'timeRestrictionLabel'>) {
  if (timeRestrictionLabel) {
    return timeRestrictionLabel
  }
  return renderTimeRestriction(timeRestriction)
}

export function constructPassTimeRestrictionLabel(
  title: string,
  description?: string | null,
) {
  if (description) {
    return `${title}, ${description}`
  }

  return title
}

export function renderPassAgeCondition({
  minAge,
  maxAge,
}: Pick<PassVariantAgeConfiguration, 'minAge' | 'maxAge'>) {
  if (minAge !== null && maxAge !== null) {
    return `${minAge} to ${maxAge} years old`
  }
  if (minAge !== null) {
    return `${minAge} years old and above`
  }
  if (maxAge !== null) {
    return `${maxAge} years old and below`
  }
  return null
}

export function calculatePassExpiry({
  validityInDays,
}: Pick<PassType, 'validityInDays'>) {
  return dayjs()
    .tz()
    .add(validityInDays - 1, 'days')
    .endOf('day')
    .toDate()
}

export const PASS_STATE = {
  PENDING_PAYMENT: 'Pending Payment',
  PAYMENT_PROCESSING: 'Payment Processing',
  EXPIRED: 'Expired',
  CANCELLED: 'Cancelled',
  VALID: 'Valid',
  FAILED: 'Failed',
  REFUND_PENDING: 'Refund Pending',
  REFUND_SUCCESS: 'Refund success',
  REFUND_FAILED: 'Refund failed',
} as const

export type PassStateType = (typeof PASS_STATE)[keyof typeof PASS_STATE]

export function getPassState({
  cancelledAt,
  expireAt,
  paymentStatus,
  hasRefundOccurred,
  refundStatus,
}: {
  cancelledAt: Pass['cancelledAt']
  expireAt: Pass['expireAt']
  paymentStatus: PaymentStatus
  hasRefundOccurred: boolean
  // refund status can be null if no refunds have ocurred before
  refundStatus: RefundStatus | null
}): PassStateType {
  if (hasRefundOccurred) {
    switch (refundStatus) {
      case 'SUCCEEDED':
        return PASS_STATE.REFUND_SUCCESS
      case 'FAILED':
        return PASS_STATE.REFUND_FAILED
    }
  }
  // TODO: Take into account consumption state.
  switch (paymentStatus) {
    case 'NONE':
      return PASS_STATE.PENDING_PAYMENT
    case 'PENDING':
      return PASS_STATE.PAYMENT_PROCESSING
    case 'CANCELLED':
      if (hasRefundOccurred) return PASS_STATE.REFUND_PENDING
      return PASS_STATE.CANCELLED
    case 'SUCCESS': {
      if (cancelledAt) {
        if (hasRefundOccurred) return PASS_STATE.REFUND_PENDING
        return PASS_STATE.CANCELLED
      }
      if (ld.isAfter(new Date(), expireAt)) {
        return PASS_STATE.EXPIRED
      }
      return PASS_STATE.VALID
    }
    case 'FAILED':
      return PASS_STATE.FAILED
    default:
      return assertUnreachable(paymentStatus)
  }
}

export const getAgeGroup = (
  age: number,
  ageGroups: Pick<PassVariantAgeConfiguration, 'group' | 'minAge' | 'maxAge'>[],
) => {
  for (const { group, minAge, maxAge } of ageGroups) {
    if ((!minAge || minAge <= age) && (!maxAge || maxAge >= age)) {
      return group
    }
  }
  return null
}

export function getRatesCancelled(data: {
  ageGroups: Record<string, number>
  passType: string
  isMultiPass: boolean
}) {
  return (
    Object.entries(data.ageGroups)
      // Not using \u00D7 as it disappears in sms
      .map(([key, val]) => (val ? `${toTitleCase(key)} x ${val}` : ''))
      .filter((val) => !!val)
      .join(' ')
  )
}

export const getCheckoutSessionLinkStatus = ({
  status,
  expiresAt,
}: {
  status: PassCheckoutSessionStatus
  expiresAt: Date
}): 'PENDING' | 'EXPIRED' | 'SUCCESS' => {
  switch (status) {
    // technically untrue, as NONE will always not be a state, putting so TS is happy
    case 'NONE':
    case 'PENDING': {
      if (expiresAt < new Date()) {
        return 'EXPIRED'
      }
      return 'PENDING'
    }
    case 'SUCCESS':
      return 'SUCCESS'
    default:
      status satisfies never
      throw new Error('Unreachable')
  }
}

export const HUMAN_READABLE_TIME_RESTRICTION_MAP: Record<
  TimeRestriction,
  string
> = {
  ALL: 'All hours',
  OFF_PEAK_ONLY: 'Off-peak hours',
  PEAK_ONLY: 'Peak hours',
}
