import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { type StyleFunctionProps } from '@chakra-ui/theme-tools'

const parts = menuAnatomy.extend('chevron')

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const getClearButtonColors = ({ colorScheme: c }: StyleFunctionProps) => {
  switch (c) {
    case 'main':
    case 'sub':
    case 'success':
    case 'critical':
    case 'warning': {
      return {
        color: `interaction.${c}.default`,
        hoverColor: `interaction.${c}.hover`,
        activeColor: `interaction.${c}.active`,
      }
    }
    case 'neutral': {
      return {
        color: 'base.content.strong',
        hoverBg: 'interaction.tinted.neutral.hover',
        activeBg: 'interaction.tinted.neutral.active',
      }
    }
    default: {
      return {
        color: `${c}.500`,
        hoverColor: `${c}.600`,
        activeColor: `${c}.700`,
      }
    }
  }
}

const variantClear = definePartsStyle((props) => {
  const { color, hoverColor, activeColor, hoverBg, activeBg } =
    getClearButtonColors(props)
  return {
    button: {
      bg: 'transparent',
      color,
      _hover: {
        color: hoverColor,
        bg: hoverBg,
      },
      _active: {
        color: activeColor,
        bg: activeBg,
      },
    },
  }
})

const variants = {
  clear: variantClear,
}

export const Menu = defineMultiStyleConfig({
  variants,
})
