import { ld } from './dates'

export function convertToStartAndEndDateTimes<
  T extends {
    date: Date
    startTime: number
    endTime: number
    startDateTime?: never
    endDateTime?: never
  },
>(object: T) {
  const { date, startTime, endTime, ...rest } = object

  const startOfDay = ld.startOfDay(date)

  return {
    ...rest,
    startDateTime: new Date(startOfDay.valueOf() + startTime),
    endDateTime: new Date(startOfDay.valueOf() + endTime),
  }
}

interface Timeslot {
  startDateTime: Date
  endDateTime: Date
}

export const mergeTimeslots = (timeslots: Timeslot[]): Timeslot[] => {
  const sortedTimeslots = timeslots.sort((a, b) => {
    return a.startDateTime.valueOf() - b.startDateTime.valueOf()
  })

  const result: Timeslot[] = []
  let currentTimeslot: Timeslot | undefined = undefined
  for (const timeslot of sortedTimeslots) {
    if (!currentTimeslot) {
      currentTimeslot = timeslot
      continue
    }
    if (
      timeslot.startDateTime.valueOf() === currentTimeslot.endDateTime.valueOf()
    ) {
      currentTimeslot = {
        startDateTime: currentTimeslot.startDateTime,
        endDateTime: timeslot.endDateTime,
      }
    } else {
      result.push(currentTimeslot)
      currentTimeslot = timeslot
    }
  }
  if (currentTimeslot) {
    result.push(currentTimeslot)
  }
  return result
}
