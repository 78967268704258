import {
  useEffect,
  type FC,
  type PropsWithChildren,
  type ReactNode,
} from 'react'
import { useRouter } from 'next/router'

import { FullscreenSpinner } from './FullscreenSpinner'

/**
 * This component is for use whenever you wish to redirect to another page on a condition without flicker.
 * The flicker can potentially last longer on low network speeds as redirecting to any page with a slug for the first time may take a few seconds.
 */
export const ConditionalRedirectWrapper: FC<
  PropsWithChildren<{
    shouldRedirect: boolean
    redirectTo: string
    /**
     * Defaults to "replace"
     * Use replace if you do not want the previous page to be in history (user cannot navigate back to it)
     * Use push if you want to preserve history stack
     * */
    redirectMethod?: 'push' | 'replace'
    /** Fallback component when in the middle of redirecting. Defaults to Spinner */
    redirectingFallback?: ReactNode
  }>
> = ({
  shouldRedirect,
  redirectTo,
  redirectMethod = 'replace',
  redirectingFallback = <FullscreenSpinner />,
  children,
}) => {
  const router = useRouter()

  useEffect(() => {
    if (shouldRedirect) {
      switch (redirectMethod) {
        case 'push':
          void router.push(redirectTo)
          break
        case 'replace':
          void router.replace(redirectTo)
          break
        default:
          redirectMethod satisfies never
          throw new Error('Invalid redirect method')
      }
    }
  }, [redirectMethod, redirectTo, router, shouldRedirect])

  // This shows when we are in the midst of redirecting to a new page
  // needed since router pushes are async, there might be flicker of old page
  if (shouldRedirect) {
    return redirectingFallback
  }

  return children
}
