import { Flex, Stack, Text } from '@chakra-ui/react'
import { Button, RestrictedGovtMasthead } from '@opengovsg/design-system-react'

import { APP_GRID_COLUMN, APP_GRID_TEMPLATE_COLUMN } from '~common/constants'

import { HOME } from '~/constants/routes'
import { AppGrid } from '~/templates/AppGrid'
import { ErrorSvgr } from '../Svgs/ErrorSvgr'

interface ErrorCardProps {
  title: string
  description?: string
}

export const ErrorCard = ({ title, description }: ErrorCardProps) => {
  return (
    <Flex flexDirection="column" h="$100vh" w="100%">
      <RestrictedGovtMasthead />
      <Flex align="end" flex={1} justify="center" px="1rem">
        <ErrorSvgr aria-hidden />
      </Flex>
      <AppGrid flex={3} templateColumns={APP_GRID_TEMPLATE_COLUMN}>
        <Stack
          align="center"
          flexDirection="column"
          gridColumn={APP_GRID_COLUMN}
          justify="space-between"
          px="1rem"
          py={{ base: '2rem', md: '4.5rem' }}
        >
          <Stack
            align="center"
            height="full"
            spacing="1.5rem"
            textAlign="center"
            width="full"
          >
            <Stack flex={{ base: 1, md: 0 }} spacing="1rem" width="full">
              <Text as="h1" textStyle={{ base: 'h4', md: 'h3' }}>
                {title}
              </Text>
              <Text textStyle="body">{description}</Text>
            </Stack>
            <Button
              variant="solid"
              width="full"
              // setting window.location directly because just using next/link will not cause the error boundary to reset
              // possible to just use useState to reset the error boundary with next/link but this is a simpler solution
              onClick={() => (window.location.href = HOME)}
            >
              Back to Home
            </Button>
          </Stack>
        </Stack>
      </AppGrid>
    </Flex>
  )
}
