import { z } from 'zod'

export type GrowthbookProfile = 'PUBLIC' | 'ADMIN'

const bannerValidationSchema = z
  .object({
    variant: z.enum(['info', 'error', 'warn']),
    message: z.string(),
  })
  .nullable()

const featurePoolListingSchema = z
  .object({ listing: z.array(z.string()) })
  .nullable()

const frequentlyAskedQuestionsValidationSchema = z
  .object({
    sections: z
      .array(
        z.object({
          question: z.string(),
          answers: z.array(z.string()).nullish(),
          answer: z.string().nullish(),
        }),
      )
      .nullish(),
  })
  .nullable()

const smsProviderValidationSchema = z.enum(['twilio', 'postman'])

export const APP_FEATURES = {
  'popularity-threshold': z.number(),
  'popularity-indicator': z.boolean(),
  'cloudflare-turnstile': z.boolean(),
  'supplementary-accounts': z.boolean(),
  'whitelist-members': z.boolean(),
  'whitelist-principals': z.boolean(),
  'admin-application-url': z.string(),
  'admin-programme-booking': z.string(),
  'member-programme-booking': z.string(),
  'member-initiated-refund': z.boolean(),
  'imitate-member': z.boolean(),
  'sms-provider': smsProviderValidationSchema,
  'completed-activesg-credit-migration': z.boolean(),
  'pilot-end-date': z.string().datetime({ offset: true }),
  'anniversary-start-date': z.string().datetime({ offset: true }),
  'anniversary-end-date': z.string().datetime({ offset: true }),
  'frequently-asked-questions': frequentlyAskedQuestionsValidationSchema,
  'quota-limit-include-children': z.boolean(),
  'passes-admin': z.boolean(),
  banner: bannerValidationSchema,
  'pass-single-entry-purchase-limit': z.number(),
  'admin-ability-to-link-accounts': z.boolean(),
  'feature-pool-listing': featurePoolListingSchema,
  'gym-capacity': z.boolean(),
  'senior-citizen-passes': z.boolean(),
  'checkout-sessions': z.boolean(),
} as const

export type AppFeatures = {
  [key in keyof typeof APP_FEATURES]: z.infer<(typeof APP_FEATURES)[key]>
}
