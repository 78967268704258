import { useMemo } from 'react'
import { HStack, Stack, Text } from '@chakra-ui/react'
import bigInt from 'big-integer'
import { groupBy } from 'lodash'

import { displayCurrency, isPresent } from '~common/utils'

export function PaymentLineItems({
  items,
}: {
  items: {
    title?: string | null
    description: string
    priceInCents: string
    discountInCents?: string | null
  }[]
}) {
  const groupedItems = useMemo(() => {
    return Object.entries(groupBy(items, (item) => item.title ?? '')).map(
      ([title, value]) => ({
        title,
        items: value,
      }),
    )
  }, [items])

  const condensedItems = useMemo(() => {
    return groupedItems.map((group) => {
      const items = Object.entries(
        groupBy(
          group.items,
          (item) =>
            `${item.description}-${item.priceInCents}-${item.discountInCents}`,
        ),
      )
        .map(([id, is]) => {
          if (!is[0]) {
            return undefined
          }
          return {
            id,
            quantity: is.length,
            ...is[0],
          }
        })
        .filter(isPresent)

      return {
        title: group.title,
        items,
      }
    })
  }, [groupedItems])

  const shouldDisplayQuantity = useMemo(() => {
    return condensedItems.reduce(
      (previous, current) =>
        previous || current.items.some((is) => is.quantity > 1),
      false,
    )
  }, [condensedItems])

  return (
    <Stack>
      {condensedItems.map(({ title, items }) => {
        return (
          <Stack key={title} spacing="0.5rem">
            {title && (
              <div>
                <Text textStyle="subhead-2">{title.split('\n')[0]}</Text>
                <Text textStyle="body-2">{title.split('\n')[1]}</Text>
              </div>
            )}
            {items.map(
              ({
                id,
                quantity,
                description,
                priceInCents,
                discountInCents,
              }) => {
                return (
                  <HStack
                    key={id}
                    justifyContent="space-between"
                    textStyle="body-2"
                  >
                    <Text as="span">
                      {shouldDisplayQuantity && `${quantity} \u00D7 `}
                      {description}
                    </Text>
                    <Text alignSelf="start">{`S$${displayCurrency(
                      bigInt(priceInCents)
                        .add(bigInt(discountInCents ?? '0'))
                        .multiply(quantity),
                    )}`}</Text>
                  </HStack>
                )
              },
            )}
          </Stack>
        )
      })}
    </Stack>
  )
}
