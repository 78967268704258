import _, { isNaN } from 'lodash'
import nric from 'nric'
import { z } from 'zod'

import { getBaseUrl } from './getBaseUrl'

export const coerceBoolean = z
  .string()
  .refine((s) => s === 'true' || s === 'false' || s === '')
  .transform((s) => s === 'true')
  .pipe(z.boolean())

export const booleanInputTransform = {
  // convert a boolean into a string
  input: (value?: boolean) => (value === undefined ? 'false' : String(value)),
  output: (value: string) => coerceBoolean.parse(value),
}

export const normaliseEmail = z
  .string()
  .trim()
  .toLowerCase()
  .min(1, 'Enter an email address.')
  .email({ message: 'Enter a valid email address.' })

export const requiredNonEmptyString = z.string().trim().min(1, 'Required')
export const requiredNonEmptyStringWithValidDomain = z
  .string()
  .trim()
  .min(1, 'Required')
  .url('Please enter a valid URL.')
  .refine(
    (val) => new URL(val).host.endsWith(new URL(getBaseUrl()).host),
    'Invalid redirect URL.',
  )

/**
 * Chainable util func to help handle '' strings to treat them as undefined.
 *
 * DEFAULT VALUES:
 * - min string length of 0
 * - max string length of 100
 *
 * Example usage:
 *
 * name: stringSchemaBuilder.optional() // min auto set to 0, max auto set to 100
 * name: stringSchemaBuilder.min(10).max(100).optional() // optional field
 * name: stringSchemaBuilder.max(200).optional() // min auto set to 0
 * name: stringSchemaBuilder.min(70).max(100).required() // required field
 *
 */
export const stringSchemaBuilder = {
  minCnt: 0,
  maxCnt: 100,
  requiredMessage: 'Please enter at least 1 character',
  setRequiredMessage(message: string) {
    this.requiredMessage = message
    return this
  },
  _minMessageConstructor: (minCnt: number) => {
    return `Please enter at least ${minCnt} characters`
  },
  _maxMessageConstructor: (maxCnt: number) => {
    return `Please enter at most ${maxCnt} characters`
  },
  setMinMessageConstructor(fn: (minCnt: number) => string) {
    this._minMessageConstructor = fn
    return this
  },
  setMaxMessageConstructor(fn: (maxCnt: number) => string) {
    this._maxMessageConstructor = fn
    return this
  },
  min(minCnt: number) {
    this.minCnt = minCnt
    return this
  },
  max(maxCnt: number) {
    this.maxCnt = maxCnt
    return this
  },
  optional() {
    return z
      .string()
      .trim()
      .optional()
      .transform((v) => (v === '' ? undefined : v))
      .pipe(
        z
          .string({ required_error: this.requiredMessage })
          .min(this.minCnt, this._minMessageConstructor(this.minCnt))
          .max(this.maxCnt, this._maxMessageConstructor(this.maxCnt))
          .optional(),
      )
  },
  nullable() {
    return z
      .string()
      .trim()
      .nullable()
      .transform((v) => (v === '' ? null : v))
      .pipe(
        z
          .string({ required_error: this.requiredMessage })
          .min(this.minCnt, this._minMessageConstructor(this.minCnt))
          .max(this.maxCnt, this._maxMessageConstructor(this.maxCnt))
          .nullable(),
      )
  },
  nullish() {
    return z
      .string()
      .trim()
      .nullish()
      .transform((v) => (v === '' ? undefined : v))
      .pipe(
        z
          .string({ required_error: this.requiredMessage })
          .min(this.minCnt, this._minMessageConstructor(this.minCnt))
          .max(this.maxCnt, this._maxMessageConstructor(this.maxCnt))
          .nullish(),
      )
  },
  required() {
    return z
      .string()
      .trim()
      .transform((v) => (v === '' ? undefined : v))
      .pipe(
        z
          .string({ required_error: this.requiredMessage })
          .min(this.minCnt, this._minMessageConstructor(this.minCnt))
          .max(this.maxCnt, this._maxMessageConstructor(this.maxCnt)),
      )
  },
}
// So when we set default state, react wont complain about uncontrollable to controllable input
export const optionalEmptyString = z
  .string()
  .trim()
  .optional()
  .transform((v) => (v === '' ? undefined : v))

export const stringId = requiredNonEmptyString.max(150)

// Allow both single string and array of strings
export const queryParamSchema = z
  .union([z.string().transform((v) => [v]), z.array(z.string())])
  .default([])

export const nricSchemaBuilder = {
  optional() {
    return z
      .string()
      .trim()
      .toUpperCase()
      .optional()
      .transform((v) => (v === '' ? undefined : v))
      .refine((val) => val === undefined || nric.validate(val), {
        message: 'Invalid NRIC',
      })
  },
  required() {
    return z
      .string()
      .trim()
      .toUpperCase()
      .refine((val) => nric.validate(val), { message: 'Invalid NRIC' })
  },
}

export const dollarStringSchema = z
  .string()
  .regex(/^\d+(.\d{2})?$/)
  .transform((valueAsString) => {
    if (!valueAsString.includes('.')) {
      return valueAsString + '.00'
    }
    return valueAsString
  })

/**
 * Construct a zod string schema between min and max values
 */
export const pctStringBuilder = (min = 0, max = 100) => {
  return z
    .string()
    .refine((v) => {
      const num = parseFloat(v)

      return !isNaN(num)
    }, 'Enter a valid number')
    .refine((v) => {
      const num = parseFloat(v)

      return num >= min && num <= max
    }, `Enter a percentage between ${min} and ${max}`)
}

// For use on backend to convert dollars to price in cents without losing precision
export const parseDollarStringToPriceInCents = z
  .string()
  .regex(/^\d+(.\d{0,2})?$/)
  .transform((valueAsString) => {
    const [dollars, cents = ''] = valueAsString.split('.')
    const paddedCents = cents.padEnd(2, '0') // Ensure there are exactly 2 digits in the cents part
    return BigInt(dollars + paddedCents)
  })
  .pipe(z.bigint().nonnegative())
