import { type SelectExpression, type Selection, type Simplify } from 'kysely'

import { type Blacklist, type DB, type PaymentItem } from './generated-types'

// Add more as needed
export type SelectFromExpr<TB extends keyof DB> = SelectExpression<DB, TB>

export type Selected<
  TB extends keyof DB,
  SE extends SelectFromExpr<TB> | SelectFromExpr<TB>[],
> =
  SE extends ArrayLike<unknown>
    ? Simplify<Selection<DB, TB, SE[number]>>
    : Simplify<Selection<DB, TB, SE>>

export enum Day {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum Period {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export type PayableEntity = PaymentItem['entity']

export const PAYMENT_ITEM_ENTITIES = [
  'Booking',
  'Pass',
  'ProgrammeSessionBooking',
] as const satisfies PrismaJson.PaymentItemEntity[]

export type BlacklistChange<T extends keyof Blacklist> = {
  field: T
  oldValue: Blacklist[T]
  newValue: Blacklist[T]
}
