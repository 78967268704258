import React, { useMemo } from 'react'
import { Divider, HStack, Stack, Text } from '@chakra-ui/react'
import bigInt, { type BigInteger } from 'big-integer'
import { groupBy } from 'lodash'
import { BiReceipt } from 'react-icons/bi'

import { InfoSection } from '~common/components/InfoSection'
import {
  BUSINESS_NAME,
  getPreGstAmount,
  GST_REGISTRATION_NUMBER,
} from '~common/constants'
import { dayjs, displayCurrency, isPresent } from '~common/utils'

import { PaymentReceiptCreditUsage } from './Payment/PaymentCreditUsage'
import { PaymentLineItems } from './Payment/PaymentLineItems'

export interface PaymentReceiptProps {
  items: {
    title?: string | null
    description: string
    priceInCents: string
    discountInCents: string | null
    creditsUsedInCents: string | null
    // Optional for backward compatibility
    // Include id for deconflicting names
    creditUser?: { id: string; name: string } | null
  }[]
  total: BigInteger
  receipt: {
    receiptNumber: string
    paidAt: Date
  } | null
}

export const PaymentReceipt = ({
  items,
  total,
  receipt,
}: PaymentReceiptProps): JSX.Element => {
  const {
    totalAmountString,
    totalDiscountString,
    cashPaidString,
    gstAmountString,
    preGstAmountString,
    isCreditUsed,
    creditUsageBreakdown,
  } = useMemo(() => {
    const year = dayjs().tz().year()
    const totalDiscount = items.reduce(
      (prev, i) => prev.add(bigInt(i.discountInCents ?? '0')),
      bigInt(0),
    )
    const cashPaid = total.minus(
      items.reduce(
        (prev, { creditsUsedInCents }) => prev.add(creditsUsedInCents ?? 0),
        bigInt(0),
      ),
    )

    const preGstAmount = getPreGstAmount(cashPaid, year)
    const gstAmount = cashPaid.minus(preGstAmount)

    const totalAmountString = `S$${displayCurrency(total.add(totalDiscount))}`
    const totalDiscountString = totalDiscount.equals(0)
      ? undefined
      : `-S$${displayCurrency(totalDiscount)}`
    const cashPaidString = `S$${displayCurrency(cashPaid)}`
    const preGstAmountString = `S$${displayCurrency(preGstAmount)}`
    const gstAmountString = `S$${displayCurrency(gstAmount)}`

    const creditUsageBreakdown = Object.values(
      groupBy(items, (item) => item.creditUser?.id ?? ''),
    )
      .map((items) => {
        if (!items[0]) {
          return undefined
        }
        return {
          id: items[0].creditUser?.id,
          name: items[0].creditUser?.name,
          totalCreditsUsedInCents: items.reduce(
            (previous, current) =>
              previous.add(current.creditsUsedInCents ?? 0),
            bigInt(0),
          ),
        }
      })
      .filter(isPresent)
      .filter(({ totalCreditsUsedInCents }) => totalCreditsUsedInCents.gt(0))
      .map((i) => ({
        ...i,
        totalCreditsUsedInCents: i.totalCreditsUsedInCents.toString(),
      }))

    const isCreditUsed = !!creditUsageBreakdown.length

    return {
      totalAmountString,
      totalDiscountString,
      cashPaidString,
      gstAmountString,
      preGstAmountString,
      isCreditUsed,
      creditUsageBreakdown,
    }
  }, [total, items])

  return (
    <InfoSection label="Payment Receipt" labelIcon={BiReceipt}>
      <Stack spacing="1rem">
        <Stack divider={<Divider borderColor="base.divider.medium" />}>
          <PaymentLineItems items={items} />
          <Stack>
            {(isCreditUsed || totalDiscountString) && (
              <>
                <HStack
                  color="grey.500"
                  justifyContent="space-between"
                  textStyle="body-2"
                >
                  <Text>Subtotal</Text>
                  <Text>{totalAmountString}</Text>
                </HStack>
                {isCreditUsed && (
                  <PaymentReceiptCreditUsage
                    creditUsages={creditUsageBreakdown}
                  />
                )}
                {totalDiscountString && (
                  <HStack
                    color="grey.500"
                    justifyContent="space-between"
                    textStyle="body-2"
                  >
                    <Text>Total discount</Text>
                    <Text>{totalDiscountString}</Text>
                  </HStack>
                )}
              </>
            )}

            <HStack alignItems="end" justifyContent="end" spacing="0.25rem">
              <Text color="grey.500">Total (excl. GST): </Text>
              <Text color="grey.500" textStyle="h6">
                {preGstAmountString}
              </Text>
            </HStack>
            <HStack alignItems="end" justifyContent="end" spacing="0.25rem">
              <Text color="grey.500">GST: </Text>
              <Text color="grey.500" textStyle="h6">
                {gstAmountString}
              </Text>
            </HStack>
            <HStack alignItems="end" justifyContent="end" spacing="0.25rem">
              <Text color="grey.900">Total: </Text>
              <Text color="grey.900" textStyle="h6">
                {cashPaidString}
              </Text>
            </HStack>
          </Stack>
        </Stack>
        {receipt && (
          <Stack
            alignItems="flex-end"
            spacing="0.25rem"
            textStyle="caption-2"
            w="full"
          >
            <Text>{receipt.receiptNumber}</Text>
            <Text>
              {dayjs(receipt.paidAt).tz().format('ddd, D MMM YYYY, hh:mm')}
            </Text>
            <HStack justifyContent="space-between" spacing="0.25rem" w="full">
              <Text>{BUSINESS_NAME}</Text>
              <Text>{GST_REGISTRATION_NUMBER}</Text>
            </HStack>
          </Stack>
        )}
      </Stack>
    </InfoSection>
  )
}
