import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  type SystemStyleObject,
} from '@chakra-ui/styled-system'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const fullDialogStyle: SystemStyleObject = {
  maxW: '100vw',
  minH: '$100vh',
  my: 0,
  borderRadius: 0,
}

const mobileDialogStyle: SystemStyleObject = {
  maxW: '100vw',
  maxH: '$100vh',
  minH: 0,
  borderRadius: '4px',
  mx: '1.5rem',
  my: '1.5rem',
  w: 'fit-content',
  h: 'fit-content',
}

export const Modal = defineMultiStyleConfig({
  sizes: {
    mobile: definePartsStyle({
      dialog: mobileDialogStyle,
      dialogContainer: {
        alignItems: 'center',
      },
      footer: {
        py: '1.5rem',
      },
    }),
    full: definePartsStyle({
      dialog: fullDialogStyle,
    }),
  },
})
