import { breadcrumbAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

export const Breadcrumb = defineMultiStyleConfig({
  defaultProps: {
    size: 'sm',
  },
})
