import { forwardRef, useCallback, useEffect, useState } from 'react'
import {
  NumberInput,
  NumberInputField,
  type NumberInputFieldProps,
  type NumberInputProps,
} from '@chakra-ui/react'

import { POSTGRES_MAX_SAFE_INTEGER } from '~common/server/locks'

// Acceptable inputs, allows intermediate values like `1.`
const INTEGER_REGEX = /^[0-9]*$/
const DECIMAL_REGEX = /^[0-9]*(\.[0-9]{0,2})*$/ // max 2dp
const isValidInput = (v: string, integerOnly: boolean) => {
  const valid = integerOnly ? INTEGER_REGEX.test(v) : DECIMAL_REGEX.test(v)
  return valid
}

// Well-formed integer or decimal, does not allow intermediate values
const VALID_INTEGER_NUMBER = /^[0-9]+$/
const VALID_DECIMAL_NUMBER = /^[0-9]+\.[0-9]{1,2}$/
const isValidNumber = (v: string) => {
  const notNan = !isNaN(Number(v))
  const validInt = VALID_INTEGER_NUMBER.test(v)
  const validDec = VALID_DECIMAL_NUMBER.test(v)
  return validInt || validDec || notNan
}

type InitialValue = StringInitialValue | NumberInitialValue

type StringInitialValue = {
  zodType: 'string'
  value: string | undefined | null
}

type NumberInitialValue = {
  zodType: 'number'
  value: number | undefined | null
}

type PositiveNumberInputProps = Omit<NumberInputProps, 'value' | 'onChange'> & {
  initialValue: InitialValue
  onChange: (value: string | number | undefined) => void
  integerOnly?: boolean
  watchedValue?: number | string
  fieldProps?: NumberInputFieldProps
}

const getInitialValue = (initialValue: InitialValue) => {
  if (initialValue.value === undefined || initialValue.value === null) {
    return ''
  }

  if (initialValue.zodType === 'string') {
    return initialValue.value
  }

  return initialValue.value.toString()
}

/** COPY PASTE FROM CARE360 PositiveNumberInput */
// Extend Chakra's NumberInput for some extra validation
export const PositiveNumberInput = forwardRef<
  'input',
  PositiveNumberInputProps
>(
  (
    {
      initialValue,
      onChange: onChangeProp,
      integerOnly = false,
      watchedValue,
      placeholder,
      fieldProps,
      ...rest
    },
    ref,
  ) => {
    const [value, setValue] = useState<string>(getInitialValue(initialValue))

    useEffect(() => {
      if (initialValue.value === null || initialValue.value === undefined) {
        setValue('')
      }
    }, [initialValue.value])

    useEffect(() => {
      if (watchedValue !== undefined) {
        setValue(
          typeof watchedValue === 'number'
            ? watchedValue.toString()
            : watchedValue,
        )
      }
    }, [watchedValue])

    const onChange = useCallback(
      (newValue: string) => {
        if (!isValidInput(newValue, integerOnly)) return
        setValue(newValue)

        if (newValue === '') {
          // set to undefined so `optional()` zod validation can pass, and non-optional validations will be set to null in database if needed
          onChangeProp(undefined)
          return
        }

        // Set if number is valid -- must be a well-formed integer or decimal
        if (isValidNumber(newValue)) {
          if (initialValue.zodType === 'string') {
            onChangeProp(newValue)
          } else {
            onChangeProp(Number(newValue))
          }
        }
      },
      [initialValue.zodType, integerOnly, onChangeProp],
    )

    return (
      <NumberInput
        ref={ref}
        clampValueOnBlur={true}
        max={POSTGRES_MAX_SAFE_INTEGER}
        value={value}
        onChange={(valueAsString) => onChange(valueAsString)}
        {...rest}
      >
        <NumberInputField placeholder={placeholder} {...fieldProps} />
      </NumberInput>
    )
  },
)

PositiveNumberInput.displayName = 'PositiveNumberInput'
