import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { anatomy } from '@chakra-ui/theme-tools'

const parts = anatomy('stepper').parts(
  'stepper',
  'step',
  'title',
  'description',
  'indicator',
  'separator',
  'icon',
  'number',
)

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  indicator: {
    bgColor: 'base.content.strong',
    color: 'white',
    textStyle: 'subhead-2',
    '&[data-status=active], &[data-status=complete]': {
      bg: 'linear-gradient(178deg, #E72547 0%, #FA6C2C 100%)',
      border: 'none',
    },
    '&[data-status=incomplete]': {
      borderColor: 'interaction.sub-subtle.default',
    },
  },
  step: {
    gap: 0,
  },
  separator: {
    '&[data-orientation=horizontal], &[data-orientation=vertical]': {
      margin: 0,
      minWidth: '20px',
      height: '1px',
      bgColor: 'base.divider.strong',
    },
  },
})

export const Stepper = defineMultiStyleConfig({
  baseStyle,
})
