import { env } from '@activesg/env'

/**
 * Retrieves the base URL for the current environment.
 * This only retrieves the base URL for the application which it is called from.
 */
export const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    return window.location.origin
  }
  if (env.NEXT_PUBLIC_APP_URL) {
    return env.NEXT_PUBLIC_APP_URL
  }
  // reference for vercel.com
  if (process.env.VERCEL_BRANCH_URL) {
    return `https://${process.env.VERCEL_BRANCH_URL}`
  }
  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3001}`
}
