import bigInt, { type BigInteger } from 'big-integer'
import SuperJSON from 'superjson'

SuperJSON.registerCustom<BigInteger, string>(
  {
    isApplicable: (v): v is BigInteger => bigInt.isInstance(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => bigInt(v),
  },
  'big-integer',
)
